import React from 'react';
import propTypes from 'prop-types';

import './Header.scss';

//TODO: Support props.titleicons

const Header = props => (
  <header className={`header ${props.type ? props.type : ''}`}>
    {
      props.pretitle &&
      <h4 className="header__pretitle" style={{ width: props.width }}>{props.pretitle}</h4>
    }
    {
      props.title &&
      <h2 className="header__title" style={{ width: props.width }}>{props.title}</h2>
    }
    {
      props.subtitle &&
      <h3 className="header__subtitle" style={{ width: props.width }}>{props.subtitle}</h3>
    }
  </header>
);

export default Header;

Header.propTypes = {
  title: propTypes.string,
  subtitle: propTypes.string,
  pretitle: propTypes.string,
  type: propTypes.string,
  width: propTypes.string,
};
