export const GO_TO_SECTION = 'GO_TO_SECTION';
export const GO_TO_GROUP = 'GO_TO_GROUP';
export const GO_TO_STAGE = 'GO_TO_STAGE';

export const TURN_OFF_ONBOARDING = 'TURN_OFF_ONBOARDING';

export const SET_MODAL = 'SET_MODAL';
export const SET_LOADER = 'SET_LOADER';
export const SET_OVERLAY = 'SET_OVERLAY';
export const SET_UNITY_DISPLAY = 'SET_UNITY_DISPLAY';
export const SET_UNITY_PROGRESS = 'SET_UNITY_PROGRESS';
export const CALL_UNITY_SCENE = 'CALL_UNITY_SCENE';

export const PLAY_IMAGE_SEQUENCE = 'PLAY_IMAGE_SEQUENCE';
export const STOP_IMAGE_SEQUENCE = 'STOP_IMAGE_SEQUENCE';

export const SET_NEXT_IMAGES_LOADED = 'SET_NEXT_IMAGES_LOADED';
export const SET_NEXT_VIDEO_LOADED = 'SET_NEXT_VIDEO_LOADED';

export const SET_MOUSE_FRAME = 'SET_MOUSE_FRAME';

export const SET_MEDIAMODAL = 'SET_MEDIAMODAL';
