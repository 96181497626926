import React from 'react';
import propTypes from 'prop-types';
import { connect } from 'react-redux';
// import Transition from 'react-transition-group/Transition';
// import TransitionGroup from 'react-transition-group/TransitionGroup';

import VideoOverlay from '../VideoOverlay/VideoOverlay';
// import LoadScreen from '../LoadScreen/LoadScreen';

import * as actions from '../../actions/index';

import './UnityDisplay.scss';

class UnityDisplay extends React.Component {
  componentDidMount() {
    console.log('UnityDisplay mounted', this.props);
    this.props.setOverlay(false);
    this.props.callUnityScene(1);
    // this.props.setUnityDisplay(true);
  }

  componentWillUnmount() {
    this.props.callUnityScene(0);
    // this.props.setOverlay(true);
  }

  render() {
    const item = this.props.data;
    return (
      <section className={`unityDisplay ${this.props.data.style}`}>
        {item.overlay && item.overlay.video &&
          <VideoOverlay
            icon="interactive"
            type="unity"
            data={this.props.data.overlay}
            iconText="3D interactive view"
          />
        }
      </section>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    setOverlay: (bool) => {
      dispatch(actions.setOverlay(bool));
    },
    setUnityDisplay: (bool) => {
      dispatch(actions.setUnityDisplay(bool));
    },
    callUnityScene: (num) => {
      dispatch(actions.callUnityScene(num));
    },
  };
}

function mapStateToProps(state) {
  return {
    overlay: state.navigation.overlay,
    currentSection: state.navigation.currentSection,
  };
}

UnityDisplay.propTypes = {
  data: propTypes.object.isRequired,
  overlay: propTypes.bool.isRequired,
  setOverlay: propTypes.func.isRequired,
  callUnityScene: propTypes.func.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(UnityDisplay);

