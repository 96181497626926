import React from 'react';
import propTypes from 'prop-types';

// import icons from '../../data/icons';

import './CalloutMulti.scss';

const CalloutMulti = props => (
  <section className="CalloutMulti">
    {props.data.title &&
      <h2 className="CalloutMulti__title">
        {props.data.title}
      </h2>
    }
    <section className={`CalloutMulti__main ${props.data.style}`}>
      {
        props.data.rows.map((calloutRow, calloutRowNum) => (
          <section className="calloutRow" key={'calloutRow' + calloutRowNum}>
            {
              calloutRow.title &&
              <section className="callOutRow__break">{calloutRow.title}</section>
            }
            {
              calloutRow.columns.map((calloutCol, calloutColNum) => (
                <section key={'calloutRow' + calloutRowNum + 'Col' + calloutColNum} className="CalloutMulti__callout" style={{ backgroundImage: `url(${calloutCol.icon})` }}>
                  <section className="CalloutMulti__textArea">
                    {
                      calloutCol.boldText &&
                      <h3 className="CalloutMulti__boldText">{calloutCol.boldText}</h3>
                    }
                    {
                      calloutCol.text &&
                      <p className="CalloutMulti__text">{calloutCol.text}</p>
                    }
                  </section>
                </section>
              ))
            }
          </section>
        ))
      }
    </section>
  </section>
);

export default CalloutMulti;

CalloutMulti.propTypes = {
  data: propTypes.shape({
    title: propTypes.string,
    rows: propTypes.array.isRequired,
    style: propTypes.string,
  }),
};


/*
  lol ;)
    <table className="no-table">
        props.data.rows.map((calloutRow, calloutRowNum) => (
          <tbody key={"calloutRow"+calloutRowNum}>
            <tr>
              <td colspan={calloutRow.cols.length}>{calloutRow.title}</td>
            </tr>
            {callOutRow.cols.map(calloutCol => (
              <tr>
                  <td><img src={calloutCol.icon} /></td>
                  <td>
                    <h3>{calloutCol.boldText}</h3>
                    <div>{calloutCol.text}</div>
                  </td>
                )
              )
              </tr>
            }
          </tbody>
        ))
    </table>*/ // :D \o/ \o/