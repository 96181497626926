import React from "react";
import propTypes from "prop-types";
import { connect } from "react-redux";
import * as actions from "../../actions";
import icons from "../../data/icons";

import "./VerticalNav.scss";

class VerticalNav extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      navItems: []
    };

    this.forWeb = true;
  }

  componentDidMount() {
    this.setState({
      navItems: this.props.navItems.map(navItem => navItem)
    });
  }

  onClick(index, event) {
    if (event) {
      event.preventDefault(); //preventing bug on web
    }
    console.log(index);
    this.props.goToSection(index);
  }

  exitApp(e) {
    e.preventDefault();
    this.props.setModal(7);

    // Just exit the app (for now)
    // if(window.require) {
    // 	const { app } = window.require('electron').remote;
    // 	app.quit();
    // }
  }

  render() {
    return (
      <nav className={`verticalNav ${!this.props.onboarding ? "border" : ""}`}>
        <ul className="verticalNav__list">
          {this.state.navItems
            .map((item, i) => (
              <a
                href="/"
                key={"verticalNav__item-" + i}
                className={`verticalNav__item ${
                  this.props.currentSection === i ? "isActive" : ""
                }`}
                onClick={e => this.onClick(i, e)}
              >
                <img
                  className="verticalNav__icon"
                  alt={item.navIcon.alt}
                  src={icons[item.navIcon.src][item.navIcon.type]}
                />
                <span className="verticalNav__link">{item.label}</span>
              </a>
            ))
            .concat([
              window.require && !this.forWeb && (
                <a
                  href="/"
                  className="verticalNav__item verticalNav__exit"
                  key="exit"
                  onClick={(e) => this.exitApp(e)}
                >
                  <img
                    className="verticalNav__icon"
                    alt="exit"
                    src={icons["exit"]["svg"]}
                  />
                  <span className="verticalNav__link">Exit</span>
                </a>
              )
            ])}
        </ul>
      </nav>
    );
  }
}

function mapStateToProps(state) {
  return {
    currentSection: state.navigation.currentSection,
    onboarding: state.navigation.onboarding
  };
}

function mapDispatchToProps(dispatch) {
  return {
    goToSection: sectionID => {
      dispatch(actions.goToSection(sectionID));
    },
    goToStage: stageID => {
      dispatch(actions.goToStage(stageID));
    },
    setModal: modalId => {
      dispatch(actions.setModal(modalId));
    }
  };
}

VerticalNav.propTypes = {
  goToSection: propTypes.func.isRequired,
  setModal: propTypes.func.isRequired,
  currentSection: propTypes.number.isRequired,
  navItems: propTypes.array.isRequired
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(VerticalNav);
