/* eslint-disable */

const setupSwipe = function(t,e){"use strict";"initCustomEvent"in e.createEvent("CustomEvent")&&(t.CustomEvent=function(t,n){n=n||{bubbles:!1,cancelable:!1,detail:void 0};var u=e.createEvent("CustomEvent");return u.initCustomEvent(t,n.bubbles,n.cancelable,n.detail),u},t.CustomEvent.prototype=t.Event.prototype),e.addEventListener("touchstart",function(t){if("true"===t.target.getAttribute("data-swipe-ignore"))return;l=t.target,i=Date.now(),n=t.touches[0].clientX,u=t.touches[0].clientY,a=0,o=0},!1),e.addEventListener("touchmove",function(t){if(!n||!u)return;var e=t.touches[0].clientX,i=t.touches[0].clientY;a=n-e,o=u-i},!1),e.addEventListener("touchend",function(t){if(l!==t.target)return;var e=parseInt(l.getAttribute("data-swipe-threshold")||"20",10),s=parseInt(l.getAttribute("data-swipe-timeout")||"500",10),r=Date.now()-i,c="";Math.abs(a)>Math.abs(o)?Math.abs(a)>e&&r<s&&(c=a>0?"swiped-left":"swiped-right"):Math.abs(o)>e&&r<s&&(c=o>0?"swiped-up":"swiped-down");""!==c&&(l.dispatchEvent(new CustomEvent(c,{bubbles:!0,cancelable:!0})));n=null,u=null,i=null},!1);var n=null,u=null,a=null,o=null,i=null,l=null};

// setupSwipe(window, document);

const triggerSwipes = function(target, dir, event) {
	if(target && target.className && target.className.indexOf('receives-swipes') > -1) {
		var swipeEvent = new CustomEvent('onSwipe', { detail: { originalEvent: event, direction: dir }});
		target.dispatchEvent(swipeEvent);
	} else {
		if(target && target.parentNode) {
			// console.log('testing', target.parentNode)
			triggerSwipes(target.parentNode, dir, event);
		}
	}
}

document.addEventListener('swiped-up', function(e) {
    triggerSwipes(e.target, 'up', e);
});

document.addEventListener('swiped-down', function(e) {
    triggerSwipes(e.target, 'down', e);
});

document.addEventListener('swiped-left', function(e) {
    triggerSwipes(e.target, 'left', e);
});

document.addEventListener('swiped-right', function(e) {
    triggerSwipes(e.target, 'right', e);
});

export default setupSwipe;
