import testJpg from '../assets/images/thumbnails/thumbnailTest.jpg';
import planJpg from '../assets/images/thumbnails/plan.jpg';
import buildJpg from '../assets/images/thumbnails/build.jpg';
import operateJpg from '../assets/images/thumbnails/operate.jpg';
import digitalJpg from '../assets/images/thumbnails/digital.jpg';

const thumbnails = {
	test: {
		jpg: testJpg,
	},
	plan: {
		jpg: planJpg,
	},
	build: {
		jpg: buildJpg,
	},
	operate: {
		jpg: operateJpg,
	},
	digital: {
		jpg: digitalJpg,
	},
};

export default thumbnails;
