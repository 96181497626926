import React from 'react';
import { connect } from 'react-redux';
import propTypes from 'prop-types';

import NavBox from '../NavBox/NavBox';
import Header from '../Header/Header';

import * as actions from '../../actions/index';

import './Portfolio.scss';

const Portfolio = (props) => {
  function onClick(index) {
    props.goToGroup(index);
  }
  return (
    <section className={"portfolio " + (props.unityDisplay ? 'hide' : '')}>
      <Header
        title={props.data.title}
        subtitle={props.data.subtitle}
        type="wide"
      />
      <section className="portfolio__navArea">
        {
          props.data.portfolio.map((item, i) => (
            <NavBox
              key={"navBox-"+i}
              title={item.title}
              description={item.description}
              thumbnail={item.thumbnail}
              image={item.image}
              navTo={item.navTo}
              isLargeBox
              onClick={onClick}
            />
          ))
        }
      </section>
    </section>
  );
};

function mapStateToProps(state) {
  return {
    unityDisplay: state.navigation.unityDisplay,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    goToGroup: (groupID) => {
      dispatch(actions.goToGroup(groupID));
    },
  };
}

Portfolio.propTypes = {
  data: propTypes.object,
  goToGroup: propTypes.func.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(Portfolio);
