import React from 'react';
import propTypes from 'prop-types';

import './FullHeader.scss';

const FullHeader = props => (
  <header className={`fullheader ${props.type ? props.type : ''}`}>
    {
      props.pretitle &&
      <h4 className="fullheader__pretitle" style={{ width: props.width }}>{props.pretitle}</h4>
    }
    {
      props.title &&
      <h2 className="fullheader__title" style={{ width: props.width }}>{props.title}</h2>
    }
    {
      props.subtitle &&
      <h3 className="fullheader__subtitle" style={{ width: props.width }}>{props.subtitle}</h3>
    }
  </header>
);

export default FullHeader;

FullHeader.propTypes = {
  title: propTypes.string,
  subtitle: propTypes.string,
  pretitle: propTypes.string,
  type: propTypes.string,
  width: propTypes.string,
};
