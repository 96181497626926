import React from "react";
import propTypes from "prop-types";
import { connect } from "react-redux";

import "./SplashScreen.scss";

class SplashScreen extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      hidden: false
    };
  }

  componentWillUnmount() {
    clearTimeout(this.timeout);
  }
  componentDidMount() {
    if (this.refs.video) {
      this.refs.video.load();
      this.refs.video.play();
    }
    this.timeout = setTimeout(() => this.setState({ hidden: true }), 2500);
  }

  componentWillReceiveProps(nextProps) {
    const video = this.refs.video;
    // video.removeEventListener('ended');
    // video.addEventListener('ended', () => console.log('ended'));
    // video.addEventListener('ended', () => this.setState({hidden: true}));

    if (!nextProps.loader && video) {
      video.play();
    }
  }

  render() {
    return (
      <section
        className={
          "splashScreen " +
          (this.props.data.style ? this.props.data.style : "") +
          " " +
          (this.state.hidden ? "hidden" : "")
        }
      >
        {this.props.data.image && (
          <img
            className={`splashScreen__image ${
              this.props.data.image.fit ? this.props.data.image.fit : ""
            }`}
            src={this.props.data.image.src}
            alt={this.props.data.image.alt}
            draggable="false"
          />
        )}
      </section>
    );
  }
}

SplashScreen.propTypes = {
  data: propTypes.shape({
    image: propTypes.object,
    video: propTypes.object.isRequired
  })
};

export default connect(null)(SplashScreen);
