import * as types from './types';

// conditional require

export function goToSection(sectionID) {
	return (dispatch) => {
		dispatch({
			type: types.GO_TO_SECTION,
			currentSection: sectionID,
		});
	}
}

export function goToGroup(groupID) {
	return (dispatch) => {
		dispatch({
			type: types.GO_TO_GROUP,
			currentGroup: groupID,
		});
	}
}

export function goToStage(stageID) {
	return (dispatch) => {
		dispatch({
			type: types.GO_TO_STAGE,
			currentStage: stageID,
		});
	}
}

export function playImageSequence() {
	return (dispatch) => {
		dispatch({
			type: types.PLAY_IMAGE_SEQUENCE,
			imageSequenceIsPlaying: true,
		});
	};
}

export function stopImageSequence() {
	return (dispatch) => {
		dispatch({
			type: types.STOP_IMAGE_SEQUENCE,
			imageSequenceIsPlaying: false,
		});
	};
}

export function setNextImagesLoaded() {
	return (dispatch) => {
		dispatch({
			type: types.SET_NEXT_IMAGES_LOADED,
			nextImagesLoaded: true,
		});
	}
}

export function setNextVideoLoaded() {
	return (dispatch) => {
		dispatch({
			type: types.SET_NEXT_VIDEO_LOADED,
			nextVideoLoaded: true,
		});
	}
}

export function setMouseFrame(frame) {
	return (dispatch => {
		dispatch({
			type: types.SET_MOUSE_FRAME,
			mouseFrame: frame,
		});
	});
}

export function setModal(index) {
	return (dispatch => {
		dispatch({
			type: types.SET_MODAL,
			modal: index,
		});
	});
}

export function setMediaModal(media) {
	return (dispatch => {
		dispatch({
			type: types.SET_MEDIAMODAL,
			media: media,
		});
	});
}

export function setOverlay(bool) {
	return (dispatch => {
		dispatch({
			type: types.SET_OVERLAY,
			overlay: bool,
		});
	});
}

export function setUnityDisplay(bool) {
	return (dispatch => {
		dispatch({
			type: types.SET_UNITY_DISPLAY,
			unityDisplay: bool,
		});
	});
}

export function setUnityProgress(percentage) {
	return (dispatch => {
		dispatch({
			type: types.SET_UNITY_PROGRESS,
			unityProgress: percentage,
		});
	});
}

export function callUnityScene(num) {
	return (dispatch => {
		dispatch({
			type: types.CALL_UNITY_SCENE,
			unitySceneCalled: num,
		});
	});
}

export function setLoader(bool) {
	return (dispatch => {
		dispatch({
			type: types.SET_LOADER,
			loader: bool,
		});
	});
}

export function turnOffOnboarding() {
	return ((dispatch) => {
		dispatch({
			type: types.TURN_OFF_ONBOARDING,
		});
	});
}



// Uncomment these if you want to use electron's internal storage
// const electron = window.require && window.require('electron');
// const ipcRenderer = electron && electron.ipcRenderer;

// const storage = ipcRenderer ? {
// 	get: function (key, callback) { // need callback to send return
// 		if (typeof key === 'string' && typeof callback === 'function') {
// 			console.log('asking electron for data from storage', key);
// 			const handler = function (event, outKey, error, data) {
// 				// uses on instead of once to support concurrent unrelated requests
// 				console.log('got data from electron storage', key, outKey, error, data);
// 				if (outKey === key) {
// 					if ((data && !data.data) || (data && data.data && !data.data.length)) {
// 						callback('Cache only with no data', null);
// 					} else {
// 						callback(error, data);
// 					}
// 					ipcRenderer.removeListener('receive-data-from-storage', handler);
// 				}
// 			};
// 			ipcRenderer.on('receive-data-from-storage', handler);
// 			ipcRenderer.send('get-from-storage', key);
// 		}
// 	},
// 	set: function (key, obj) { //fire and forget, no callback
// 		typeof key === 'string' &&
// 			ipcRenderer.send('save-to-storage', key, obj);
// 	}
// } : null;
