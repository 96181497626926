import React from "react";
import propTypes from "prop-types";
import thumbnails from "../../data/thumbnails";

import "./NavBox.scss";

const NavBox = props => (
  <a
    className={`NavBox ${props.isLargeBox ? "large" : "small"} ${
      props.type ? props.type : ""
    } ${props.width ? "width" : ""}`}
    key={props.id}
    onClick={
      typeof props.navTo === "number"
        ? props.onClick.bind(this, props.navTo)
        : props.onClick
    }
    style={{ width: props.width }}
  >
    <section className="NavBox__progressBarContainer">
      <div className="NavBox__progressBar" />
    </section>
    {props.thumbnail && (
      <img
        className="NavBox__image"
        src={thumbnails[props.thumbnail.src][props.thumbnail.type]}
        alt={thumbnails[props.thumbnail.alt]}
      />
    )}
    {props.image && (
      <img
        className="NavBox__image"
        src={props.image.src}
        alt={props.image.alt}
      />
    )}
    <section className="NavBox__itemTextArea">
      <h4 className="NavBox__header">{props.title}</h4>
      {props.subtitle && <h5 className="NavBox__subtitle">{props.subtitle}</h5>}
      <p className="NavBox__description">{props.description}</p>
    </section>
  </a>
);

export default NavBox;

NavBox.propTypes = {
  thumbnail: propTypes.shape({
    src: propTypes.string.isRequired,
    type: propTypes.string.isRequired,
    alt: propTypes.string.isRequired
  }),
  title: propTypes.string.isRequired,
  description: propTypes.string,
  isLargeBox: propTypes.bool,
  onClick: propTypes.func,
  navTo: propTypes.number,
  image: propTypes.object,
  type: propTypes.string,
  subtitle: propTypes.string
};
