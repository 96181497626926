import React from 'react';
import propTypes from 'prop-types';

import icons from '../../data/icons';

import './Button.scss';

const Button = props => (
	<button
		className={`button ${(props.type) ? props.type : ''}`}
		style={props.style}
		onClick={props.onClick}
	>
		{
			props.icon &&
			<img className="button__icon" src={icons[props.icon].svg} alt={'icon'} />
		}
		{
			props.text &&
			<span className="button__text">{props.text}</span>
		}
		{
			props.children &&
			<span className="button__text">{props.children}</span>
		}
	</button>
);

Button.propTypes = {
	onClick: propTypes.func.isRequired,
	type: propTypes.string,
	icon: propTypes.string,
	children: propTypes.node,
};

export default Button;
