import React from 'react';
import propTypes from 'prop-types';
import { connect } from 'react-redux';
import * as actions from '../../actions/index';

import data from '../../data/modal.json';
import Header from '../Header/Header';
import icons from '../../data/icons';

import './Modal.scss';

const Modal = (props) => {
  function exitApp() {
    if (window.require) {
      const { app } = window.require('electron').remote;
      app.quit();
    }
  }

  // function giveFeedback() {
  //   const { app } = window.require('electron').remote;
  //   const { shell } = window.require('electron');
  //   shell.openExternal('https://oneabb.formstack.com/forms/boost_promotion_app_feedback_form');
  //   app.quit();
  // }

  return (
    <section className="modal">
      <section className={`modal__inner ${data[props.modal].type ? data[props.modal].type : ''}`}>
        {
          data[props.modal].type !== 'exit' &&
          <section className="modal__regular">
            <button className="modal__back" onClick={() => props.setModal(null)}>
              <img src={icons.cross.svg} alt="move timeline" className="modal__icon" />
            </button>
            <section className="modal__lhs">
              <Header
                title={data[props.modal].title}
                subtitle={data[props.modal].subtitle}
              />
              <p className="modal__text">
                {data[props.modal].text}
              </p>
              {
                data[props.modal].list &&
                <ul className="modal__list">
                  {
                    data[props.modal].list.map(item => (
                      <li key={item.id} className="modal__listItem">{item.text}</li>
                    ))
                  }
                </ul>
              }
            </section>
            <section className={`modal__rhs ${data[props.modal].style ? data[props.modal].style : ''}`}>
              <img
                className="modal__image"
                src={data[props.modal].image.src}
                alt={data[props.modal].image.alt}
              />
              {
                data[props.modal].style !== 'digital360' &&
                <section className="modal__columnArea">
                  {
                    data[props.modal].columns && data[props.modal].columns.map(column => (
                      <section key={column.id} className="modal__column">
                        <header className="modal__columnHeader">
                          <h3 className="modal__columnTitle">{column.title}</h3>
                          <h4 className="modal__columnSubtitle">{column.subtitle}</h4>
                        </header>
                        <ul className="modal__columnList">
                          {
                            column.list.map(item => (
                              <li className="modal__columnItem" key={item.id}>{item.text}</li>
                            ))
                          }
                        </ul>
                      </section>
                    ))
                  }
                </section>
              }
            </section>
          </section>
        }
        {
          data[props.modal].type === 'exit' &&
          <section className="modal__exit">
            <img src={icons.exit.svg} alt="move timeline" className="modal__exitIcon" />
            <h1 className="modal__exitHeader">
              Are you sure you want to exit the app?
            </h1>
            <section className="modal__exitOptions">
              <button className="modal__exitButton" onClick={() => exitApp()}>
                Exit
              </button>
              <button className="modal__exitButton cancel" onClick={() => props.setModal(null)}>
                Cancel
              </button>
            </section>
          </section>
        }
      </section>
    </section>
  );
};

function mapDispatchToProps(dispatch) {
  return {
    setModal: (index) => {
      dispatch(actions.setModal(index));
    },
  };
}

function mapStateToProps(state) {
  return {
    modal: state.navigation.modal,
  };
}

Modal.propTypes = {
  setModal: propTypes.func.isRequired,
  modal: propTypes.number.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(Modal);
