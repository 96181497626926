import React, { Component } from "react";
import propTypes from "prop-types";
import { connect } from "react-redux";

import * as actions from "../../actions/index";
import wheelData from "../../data/wheel.json";
import icons from "../../data/icons";

import Header from "../Header/Header";

import "./Wheel.scss";

const segmentMap = [0, 1, 3, 2];

class Wheel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      segment: null,
      page: 0
    };
  }

  setSegment(num) {
    this.setState({
      segment: segmentMap[num],
      page: 0
    });
  }

  setPage(direction) {
    this.setFadeInTransition();

    const dir = direction === "right" ? 1 : -1;

    let page = this.state.page + dir;
    let segment = this.state.segment;

    if (page > 3) {
      page = 0;
      segment = this.state.segment + dir;
    }
    if (page < 0) {
      page = 3;
      segment = this.state.segment + dir;
    }
    if (segment > 3) {
      segment = 0;
    }
    if (segment < 0) {
      segment = 3;
    }

    this.setState({
      page: page,
      segment: segment
    });
  }

  setFadeInTransition() {
    this.setState({
      hidden: true
    });
    const timeout = setTimeout(() => {
      this.setState({
        hidden: false
      });
      clearTimeout(timeout);
    }, 100);
  }

  render() {
    const segment = segmentMap[this.state.segment] || this.state.segment;
    const data = wheelData[segment] && wheelData[segment][this.state.page];
    return (
      <section className="wheel">
        <video
          className="wheel__video"
          loop
          autoPlay
          muted
          src="./assets/videos/operate-wheel.mp4"
          type="video/mp4"
        />
        <Header type="white unity" title="Service offering" />
        <section className="wheel__circle">
          <img
            className="wheel__arrows"
            src="./assets/images/operate-wheel-arrows.svg"
            alt="wheel arrows"
          />
          <div className="wheel__inner">
            <h2 className="wheel__innerText">
              System availability and reliability
            </h2>
          </div>
          <div
            className={`wheel__segment top left ${
              segment === 0 ? "selected" : ""
            }`}
          >
            <img
              className="wheel__segmentIcon"
              src={icons.upgrades.svg}
              alt="icon"
            />
            <h3 className="wheel__segmentHeader">Lifecycle management</h3>

            <button
              className={"wheel__button " + (segment === 0 ? "disabled" : "")}
              onClick={() => this.setSegment(0)}
            >
              Explore
            </button>
          </div>
          <div
            className={`wheel__segment top right ${
              segment === 1 ? "selected" : ""
            }`}
          >
            <img
              className="wheel__segmentIcon"
              src={icons.response.svg}
              alt="icon"
            />
            <h3 className="wheel__segmentHeader">Rapid response</h3>
            <button
              className={"wheel__button " + (segment === 1 ? "disabled" : "")}
              onClick={() => this.setSegment(1)}
            >
              Explore
            </button>
          </div>
          <div
            className={`wheel__segment bottom left ${
              segment === 2 ? "selected" : ""
            }`}
          >
            <img
              className="wheel__segmentIcon"
              src={icons.assessment.svg}
              alt="icon"
            />
            <h3 className="wheel__segmentHeader">Performance improvement</h3>
            <button
              className={"wheel__button " + (segment === 2 ? "disabled" : "")}
              onClick={() => this.setSegment(2)}
            >
              Explore
            </button>
          </div>
          <div
            className={`wheel__segment bottom right ${
              segment === 3 ? "selected" : ""
            }`}
          >
            <img
              className="wheel__segmentIcon"
              src={icons.laptop.svg}
              alt="icon"
            />
            <h3 className="wheel__segmentHeader">Operational excellence</h3>
            <button
              className={"wheel__button " + (segment === 3 ? "disabled" : "")}
              onClick={() => this.setSegment(3)}
            >
              Explore
            </button>
          </div>
        </section>
        {segment !== null && (
          <section className="wheel__content">
            <div
              className={`wheel__contentTextArea ${
                this.state.hidden ? "" : "showing"
              }`}
            >
              <div className="wheel__contentIconContainer">
                <img
                  className="wheel__contentIcon"
                  src={icons[data.icon].svg}
                  alt="icon"
                />
              </div>
              <h3 className="wheel__contentTitle">{data.title}</h3>
              {data.subtitle && (
                <h4 className="wheel__contentSubTitle">{data.subtitle}</h4>
              )}
              {data.list && (
                <ul className="panel__list wheelList">
                  {data.list.map(item => (
                    <li key={item.id} className="panel__listItem wheelList">
                      {item.text}
                    </li>
                  ))}
                </ul>
              )}
            </div>
            <nav className="wheel__contentNavigation">
              <button
                className="wheel__contentNavigationButton"
                onClick={() => this.setPage("left")}
              >
                <img
                  className="wheel__contentNavigationButtonIcon"
                  src={icons.arrowDown.svg}
                  alt="navigation"
                />
              </button>
              <span className="wheel__contentProgress">
                {this.state.page + 1} of {wheelData[segment].length}
              </span>
              <button
                className="wheel__contentNavigationButton right"
                onClick={() => this.setPage("right")}
              >
                <img
                  className="wheel__contentNavigationButtonIcon"
                  src={icons.arrowDown.svg}
                  alt="navigation"
                />
              </button>
            </nav>
          </section>
        )}
      </section>
    );
  }
}

Wheel.propTypes = {
  setModal: propTypes.func.isRequired
};

function mapDispatchToProps(dispatch) {
  return {
    setModal: index => {
      dispatch(actions.setModal(index));
    }
  };
}

export default connect(
  null,
  mapDispatchToProps
)(Wheel);
