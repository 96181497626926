import React from 'react';
import propTypes from 'prop-types';
import { connect } from 'react-redux';

import CalloutArea from '../CalloutArea/CalloutArea';
import CalloutMulti from '../CalloutMulti/CalloutMulti';
import IconList from '../IconList/IconList';
import ContentLinkArea from '../ContentLinkArea/ContentLinkArea';

// import appData from '../../data/app.json';
import panelData from '../../data/panel.json';

import './Panel.scss';

class Panel extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			timer: 0,
		};
	}

	render() {
		const panels = panelData[this.props.caseStudyPanel] || panelData[this.props.panel];
			//panelData[appData.sections[this.props.currentSection].panel] || 
			// panelData[appData.sections[this.props.currentSection].groups[this.props.currentGroup].stages[this.props.currentStage].panel];

		return (
			(panels &&
			<section className={`panel ${this.props.style ? this.props.style : ''}`}>
				{
					panels && panels.map((item, i) => {
						const itemId = 'panel-' + item.type + '-' + i;

						if (item.type === 'para') {
							return (
								<section key={itemId} className={`panel__para ${item.style}`}>
									<h4 className="panel__paraHeader">
										{item.header}
									</h4>
									<p className={'panel__paraText'}>{item.para}
									</p>
								</section>
							);
						}
						if (item.type === 'calloutArea') {
							return <CalloutArea key={itemId} data={item.calloutArea} style={item.style} />;
						}
						if (item.type === 'iconList') {
							return <IconList key={itemId} data={item.iconList} style={item.style} />;
						}
						if (item.type === 'calloutMulti') {
							return <CalloutMulti key={itemId} data={item.calloutMulti} />;
						}
						if (item.type === 'list') {
							return (
								<section key={itemId} className="panel__listArea">
									<h3 className="panel__listHeader">
										{item.header}
									</h3>
									<ul className="panel__list">
										{
											item.list.map((item, listId) => (
												<li className="panel__listItem" key={"panel__listItem-"+listId}>{item.text}</li>
											))
										}
									</ul>
								</section>
							)
						}
						if (item.type === 'intro') {
							return (
								<section key={itemId} className="panel__intro">
									<h3 className="panel__introTitle">
										{item.title}
									</h3>
									<h4 className="panel__introSubtitle">
										{item.subtitle}
									</h4>
									<p className="panel__introBody">
										{item.body}
									</p>
								</section>
							)
						}
						if (item.type === 'contentLink') {
							return (
								<ContentLinkArea data={item.contentLinks} />
							)
						}
						return '';
					})
				}
			</section>) || null
		);
	}
}

function mapStateToProps(state) {
	return {
		currentSection: state.navigation.currentSection,
		currentGroup: state.navigation.currentGroup,
		currentStage: state.navigation.currentStage,
	};
}

Panel.propTypes = {
	currentSection: propTypes.number.isRequired,
	currentGroup: propTypes.number,
	currentStage: propTypes.number,
	panel: propTypes.number,
	caseStudyPanel: propTypes.number,
};

export default connect(mapStateToProps)(Panel);
