import arrowSvg from "../assets/images/icons/iconArrow.svg";
import arrowWhiteSvg from "../assets/images/icons/iconArrow_white.svg";
import arrowDownSvg from "../assets/images/icons/Arrow_Down.svg";
import backSvg from "../assets/images/icons/back.svg";
import buildingSvg from "../assets/images/icons/building.svg";
import chartSvg from "../assets/images/icons/chart.svg";
//import closeSvg from '../assets/images/icons/close.svg';
import crossSvg from "../assets/images/icons/cross.svg";
import cornerstoneSvg from "../assets/images/icons/cornerstone.svg";
import customersSvg from "../assets/images/icons/value-to-customer.svg";
import disconnectorSvg from "../assets/images/icons/disconnector.png";
import digitalSvg from "../assets/images/icons/digital.svg";
import digitalThreeSixtySvg from "../assets/images/icons/360.svg";
import downSvg from "../assets/images/icons/down.svg";
import electricalSvg from "../assets/images/icons/electrical.svg";
import engineersSvg from "../assets/images/icons/engineers.svg";
import environmentSvg from "../assets/images/icons/environment.svg";
import exitSvg from "../assets/images/icons/exit.svg";
import factorySvg from "../assets/images/icons/factory.svg";
import handshakeSvg from "../assets/images/icons/handshake.svg";
import houseSvg from "../assets/images/icons/house.svg";
import healthSvg from "../assets/images/icons/health.svg";
import laptopSvg from "../assets/images/icons/laptop.svg";
import interactiveSvg from "../assets/images/icons/interactive.svg";
import manualMonitoringSvg from "../assets/images/icons/manual-monitoring.svg";
import newsSvg from "../assets/images/icons/news.svg";
import openSvg from "../assets/images/icons/open.svg";
import powerQualitySvg from "../assets/images/icons/power-quality.svg";
import powerSupplySvg from "../assets/images/icons/power-supply.svg";
import powerTransmissionSvg from "../assets/images/icons/power-transmission.svg";
//import pggiSvg from '../assets/images/icons/grid.svg';
import publicationSvg from "../assets/images/icons/publication.svg";
import renewablesSvg from "../assets/images/icons/renewables.svg";
import repairSvg from "../assets/images/icons/repair.svg";
import safetySvg from "../assets/images/icons/safety.svg";
import scrollSvg from "../assets/images/icons/scroll.svg";
import substationSvg from "../assets/images/icons/substation.svg";
import timelineArrowSvg from "../assets/images/icons/timeline.svg";
import timelineSvg from "../assets/images/icons/timeline-icon.svg";
import tosaSvg from "../assets/images/icons/tosa.svg";
import stageArrowSvg from "../assets/images/icons/stageArrow.svg";
import swipeSvg from "../assets/images/icons/swipe-down.svg";
import whiteExitSvg from "../assets/images/icons/white-exit.svg";
import whiteFactorySvg from "../assets/images/icons/white-factory.svg";
import whiteHouseSvg from "../assets/images/icons/white-house.svg";
import whiteCornerstoneSvg from "../assets/images/icons/cornerstone-white.svg";
import whiteNewsSvg from "../assets/images/icons/white-news.svg";
import whitePowerQualitySvg from "../assets/images/icons/white-power-quality.svg";
import whitePowerSupplySvg from "../assets/images/icons/white-power-supply.svg";
import whitePowerTransmissionSvg from "../assets/images/icons/white-power-transmission.svg";
import whiteSubstationSvg from "../assets/images/icons/white-substation.svg";
import whiteValueCustomerSvg from "../assets/images/icons/white-value-to-customer.svg";
import whiteRenewablesSvg from "../assets/images/icons/white-renewables.svg";
import upSvg from "../assets/images/icons/up.svg";
import circuitBreakerSvg from "../assets/images/icons/circuitBreaker.svg";
import placeholderSvg from "../assets/images/icons/placeholder.svg";
import gearSvg from "../assets/images/icons/gear.svg";
import shipSvg from "../assets/images/icons/ship.svg";
import replacementSvg from "../assets/images/icons/replacement.svg";
import switchgearSvg from "../assets/images/icons/switchgear.svg";
import trainSvg from "../assets/images/icons/train.svg";
import truckSvg from "../assets/images/icons/truck.svg";
import passSvg from "../assets/images/icons/pass.svg";
import loaderSvg from "../assets/images/icons/loader.svg";
import calendarSvg from "../assets/images/icons/calendar.svg";
import whiteShipSvg from "../assets/images/icons/white-ship.svg";
import whiteGridSvg from "../assets/images/icons/white-grid.svg";
import whiteEnvironmentSvg from "../assets/images/icons/white-environment.svg";
import operateSvg from "../assets/images/icons/operate.svg";
import solarSvg from "../assets/images/icons/solar.svg";
import abSvg from "../assets/images/icons/ab.svg";
import multiSvg from "../assets/images/icons/multi.svg";
import focsSvg from "../assets/images/icons/focs.svg";
import gisSvg from "../assets/images/icons/gis.svg";
import upgradesSvg from "../assets/images/icons/upgrades.svg";
import cyberSecuritySvg from "../assets/images/icons/cyberSecurity.svg";
import updatesSvg from "../assets/images/icons/updates.svg";
import assessmentSvg from "../assets/images/icons/assessment.svg";
import modelsSvg from "../assets/images/icons/models.svg";
import knowledgeSvg from "../assets/images/icons/knowledge.svg";
import improvementSvg from "../assets/images/icons/improvement.svg";
import onSiteSvg from "../assets/images/icons/onSite.svg";
import responseSvg from "../assets/images/icons/response.svg";
import arrowRedSvg from "../assets/images/icons/arrow-red.svg";
import infoSvg from "../assets/images/icons/info.svg";
import videoSvg from "../assets/images/icons/video.svg";
import oneSvg from "../assets/images/icons/one.svg";
import twoSvg from "../assets/images/icons/two.svg";
import threeSvg from "../assets/images/icons/three.svg";
import fourSvg from "../assets/images/icons/four.svg";
import altArrowLight from "../assets/images/icons/alt-arrow.svg";
import altArrowSvg from "../assets/images/icons/alt-arrow-grey.svg";

import craneSvg from "../assets/images/icons/new/Icon_Crane.svg";
import financialSvg from "../assets/images/icons/new/Icon_Money.svg";
import powerSvg from "../assets/images/icons/new/Icon_Power.svg";
import graphSvg from "../assets/images/icons/new/Icon_Increasing.svg";
import globeSvg from "../assets/images/icons/new/Icon_Globe.svg";
import securitySvg from "../assets/images/icons/new/Icon_Shield.svg";
import structureSvg from "../assets/images/icons/new/Icon_Service.svg";
import homeSvg from "../assets/images/icons/new/Icon_Home.svg";
import portfolioSvg from "../assets/images/icons/new/Icon_Portfolio.svg";
import footprintSvg from "../assets/images/icons/new/Icon_Footprint.svg";
import referencesSvg from "../assets/images/icons/new/Icon_References.svg";

function importAll(r) {
  let images = {};
  r.keys().map((item, index) => (images[item.replace("./", "")] = r(item)));
  return images;
}

const introImages = importAll(
  require.context(
    "../assets/images/abb-icons/Intro",
    false,
    /\.(png|jpe?g|svg)$/
  )
);
const navImages = importAll(
  require.context("../assets/images/abb-icons/Nav", false, /\.(png|jpe?g|svg)$/)
);
const networkImages = importAll(
  require.context(
    "../assets/images/abb-icons/Network",
    false,
    /\.(png|jpe?g|svg)$/
  )
);
//const portfolioImages = importAll(require.context('../assets/images/abb-icons/Portfolio', false, /\.(png|jpe?g|svg)$/));
const referencesImages = importAll(
  require.context(
    "../assets/images/abb-icons/References",
    false,
    /\.(png|jpe?g|svg)$/
  )
);

const icons = {
  exit: {
    svg: exitSvg
  },
  close: {
    svg: navImages["close.svg"]
  },
  home: {
    svg: homeSvg
  },
  portfolio: {
    svg: portfolioSvg
  },
  footprint: {
    svg: footprintSvg
  },
  references: {
    svg: referencesSvg
  },
  disconnectors: {
    svg: referencesImages["disconnectors.svg"]
  },
  network: {
    svg: navImages["network.svg"]
  },
  graph: {
    svg: graphSvg
  },
  power: {
    svg: powerSvg
  },
  crane: {
    svg: craneSvg
  },
  sdf: {
    svg: referencesImages["sdf.svg"]
  },
  edb: {
    svg: referencesImages["edb.svg"]
  },
  gw54: {
    svg: referencesImages["gw54.svg"]
  },
  gw57: {
    svg: referencesImages["gw57.svg"]
  },
  dssp: {
    svg: referencesImages["dssp.svg"]
  },
  doc: {
    svg: networkImages["document.svg"]
  },
  movie: {
    svg: networkImages["video.svg"]
  },
  chartup: {
    svg: graphSvg
  },
  securitytick: {
    svg: introImages["security.svg"]
  },
  arrow: {
    svg: arrowSvg
  },
  arrowDown: {
    svg: arrowDownSvg
  },
  //TODO: Can probably clear a lot of these out
  factory: {
    svg: factorySvg
  },
  house: {
    svg: houseSvg
  },
  news: {
    svg: newsSvg
  },
  powerQuality: {
    svg: powerQualitySvg
  },
  powerSupply: {
    svg: powerSupplySvg
  },
  powerTransmission: {
    svg: powerTransmissionSvg
  },
  substation: {
    svg: substationSvg
  },
  customers: {
    svg: customersSvg
  },
  cornerstone: {
    svg: cornerstoneSvg
  },
  open: {
    svg: openSvg
  },
  whiteCornerstone: {
    svg: whiteCornerstoneSvg
  },
  whiteFactory: {
    svg: whiteFactorySvg
  },
  whiteNews: {
    svg: whiteNewsSvg
  },
  whitePowerQuality: {
    svg: whitePowerQualitySvg
  },
  whitePowerTransmission: {
    svg: whitePowerTransmissionSvg
  },
  whitePowerSupply: {
    svg: whitePowerSupplySvg
  },
  whiteSubstation: {
    svg: whiteSubstationSvg
  },
  whiteValueCustomer: {
    svg: whiteValueCustomerSvg
  },
  up: {
    svg: upSvg
  },
  down: {
    svg: downSvg
  },
  timelineArrow: {
    svg: timelineArrowSvg
  },
  digital: {
    svg: digitalSvg
  },
  electrical: {
    svg: electricalSvg
  },
  financial: {
    svg: financialSvg
  },
  swipe: {
    svg: swipeSvg
  },
  back: {
    svg: backSvg
  },
  building: {
    svg: buildingSvg
  },
  environment: {
    svg: environmentSvg
  },
  health: {
    svg: healthSvg
  },
  safety: {
    svg: safetySvg
  },
  timeline: {
    svg: timelineSvg
  },
  engineers: {
    svg: engineersSvg
  },
  publication: {
    svg: publicationSvg
  },
  interactive: {
    svg: interactiveSvg
  },
  security: {
    svg: securitySvg
  },
  laptop: {
    svg: laptopSvg
  },
  handshake: {
    svg: handshakeSvg
  },
  globe: {
    svg: globeSvg
  },
  stageArrow: {
    svg: stageArrowSvg
  },
  scroll: {
    svg: scrollSvg
  },
  cross: {
    svg: crossSvg
  },
  digitalThreeSixty: {
    svg: digitalThreeSixtySvg
  },
  renewables: {
    svg: renewablesSvg
  },
  whiteRenewables: {
    svg: whiteRenewablesSvg
  },
  whiteHouse: {
    svg: whiteHouseSvg
  },
  whiteExit: {
    svg: whiteExitSvg
  },
  chart: {
    svg: chartSvg
  },
  manualMonitoring: {
    svg: manualMonitoringSvg
  },
  repair: {
    svg: repairSvg
  },
  tosa: {
    svg: tosaSvg
  },
  disconnector: {
    svg: disconnectorSvg
  },
  circuitBreaker: {
    svg: circuitBreakerSvg
  },
  placeholder: {
    svg: placeholderSvg
  },
  gear: {
    svg: gearSvg
  },
  structure: {
    svg: structureSvg
  },
  ship: {
    svg: shipSvg
  },
  replacement: {
    svg: replacementSvg
  },
  switchgear: {
    svg: switchgearSvg
  },
  train: {
    svg: trainSvg
  },
  pass: {
    svg: passSvg
  },
  truck: {
    svg: truckSvg
  },
  calendar: {
    svg: calendarSvg
  },
  whiteShip: {
    svg: whiteShipSvg
  },
  whiteGrid: {
    svg: whiteGridSvg
  },
  whiteEnvironment: {
    svg: whiteEnvironmentSvg
  },
  loader: {
    svg: loaderSvg
  },
  operate: {
    svg: operateSvg
  },
  solar: {
    svg: solarSvg
  },
  ab: {
    svg: abSvg
  },
  multi: {
    svg: multiSvg
  },
  focs: {
    svg: focsSvg
  },
  gis: {
    svg: gisSvg
  },
  upgrades: {
    svg: upgradesSvg
  },
  cyberSecurity: {
    svg: cyberSecuritySvg
  },
  updates: {
    svg: updatesSvg
  },
  assessment: {
    svg: assessmentSvg
  },
  models: {
    svg: modelsSvg
  },
  knowledge: {
    svg: knowledgeSvg
  },
  improvement: {
    svg: improvementSvg
  },
  onSite: {
    svg: onSiteSvg
  },
  response: {
    svg: responseSvg
  },
  arrowRed: {
    svg: arrowRedSvg
  },
  info: {
    svg: infoSvg
  },
  video: {
    svg: videoSvg
  },
  altArrow: {
    svg: altArrowSvg
  },
  altArrowLight: {
    svg: altArrowLight
  },
  one: {
    svg: oneSvg
  },
  two: {
    svg: twoSvg
  },
  three: {
    svg: threeSvg
  },
  four: {
    svg: fourSvg
  }
};

export default icons;
