import React from "react";
import propTypes from "prop-types";
import { connect } from "react-redux";

import Group from "../Group/Group";
import Portfolio from "../Portfolio/Portfolio";
import SplashScreen from "../SplashScreen/SplashScreen";

import appData from "../../data/app.json";

import "./Section.scss";

const Section = props => {
  const data = appData.sections[props.currentSection];
  return (
    (data && (
      <section
        className={`section ${
          props.unityDisplay
            ? props.currentSection === 0
              ? "noBackground"
              : "hide"
            : ""
        }`}
        style={data.image ? { backgroundImage: `url('${data.image}')` } : null}
      >
        {data.splashscreen && <SplashScreen data={data.splashscreen} />}
        {data.type === "portfolio" && (
          <Portfolio currentSection={props.currentSection} data={data} />
        )}
        {data.groups &&
          data.groups.map((item, i) => {
            return (
              props.currentGroup === i && (
                <Group
                  key={`group-${i}`}
                  data={item}
                  currentSection={props.currentSection}
                  currentGroup={props.currentGroup}
                  currentStage={props.currentStage}
                  numGroups={data.groups.length}
                  numSections={props.numSections}
                />
              )
            );
          })}
      </section>
    )) ||
    null
  );
};

function mapStateToProps(state) {
  return {
    unityDisplay: state.navigation.unityDisplay
  };
}

Section.propTypes = {
  currentSection: propTypes.number.isRequired,
  numSections: propTypes.number.isRequired,
  currentGroup: propTypes.number,
  unityDisplay: propTypes.bool.isRequired
};

export default connect(mapStateToProps)(Section);
