import React from 'react';
import propTypes from 'prop-types';

import './Intro.scss';

const Intro = props => (
  <section className="intro">
    <img className="intro__image" src={props.data.image.src} alt="background" />
    <div className="intro__overlay" />
    <h1 className="intro__title">
      {props.data.title}
    </h1>
    <h2 className="intro__subtitle">
      {props.data.subtitle}
    </h2>
    {
      props.data.text && props.data.text.map((para, i) => (
        <p key={"intro__text-"+i} className="intro__text">{para.text}</p>
      ))
    }
  </section>
);

Intro.propTypes = {
  data: propTypes.object.isRequired,
};

export default Intro;
