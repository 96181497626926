import React from "react";
import propTypes from "prop-types";
import { connect } from "react-redux";
import Unity, { RegisterExternalListener, UnityEvent } from "react-unity-webgl";

import Header from "../Header/Header";
import MoreInfo from "../MoreInfo/MoreInfo";
import Button from "../Button/Button";

import * as actions from "../../actions/index";
import icons from "../../data/icons";

import "./UnityContent.scss";

const initialState = {
  currentView: 0,
  moreInfo: null,
  title: null,
  next: null,
  baseOffset: 0
  //lastScene: null,
};

window.lastScene = null;
window.lastProduct = "sdf";
//The unity integration in this project is a mess. If you're looking to reuse, don't come here. Start over

class UnityContent extends React.Component {
  constructor(props) {
    super(props);
    this.state = initialState;
    this.onProgress = this.onProgress.bind(this);
    this.toggleOffset = this.toggleOffset.bind(this);
    this.loaded = this.props.loader;
    this.loadScene = new UnityEvent("NativeCommunication", "LoadScene");
    this.goNext = new UnityEvent("NativeCommunication", "GoNext");
    this.setOffset = new UnityEvent("NativeCommunication", "SetCameraOffset");
    this.setActiveProduct = new UnityEvent(
      "NativeCommunication",
      "SetActiveProduct"
    );
    this.setCameraPoint = new UnityEvent(
      "NativeCommunication",
      "SetCameraPoint"
    );
    this.playTimeline = new UnityEvent("NativeCommunication", "PlayTimeline");

    RegisterExternalListener("onSceneLoaded", sceneName =>
      this.onSceneLoaded(sceneName)
    );
    RegisterExternalListener("setContent", num => this.setCurrentView(num));
  }

  componentWillMount() {
    this.props.setLoader(true);
  }
  componentWillReceiveProps(nextProps) {
    // if there is a unity property on stage data object
    //console.log('in UnityContent willRec', nextProps)
    if (nextProps.unitySceneCalled) {
      const stage =
        nextProps.data.sections[nextProps.currentSection] &&
        nextProps.data.sections[nextProps.currentSection].groups[
          nextProps.currentGroup
        ] &&
        nextProps.data.sections[nextProps.currentSection].groups[
          nextProps.currentGroup
        ].stages[nextProps.currentStage] &&
        nextProps.data.sections[nextProps.currentSection].groups[
          nextProps.currentGroup
        ].stages[nextProps.currentStage];
      const unity = stage && stage.unity;
      if (unity) {
        //console.log('same scene?', window.lastScene, unity.sceneName[0]);
        if (unity.sceneName && window.lastScene !== unity.sceneName[0]) {
          this.props.setLoader(true);
          // this.loaded = false;

          if (this.loadTimeout) {
            clearTimeout(this.loadTimeout);
          }
          this.loadTimeout = setTimeout(
            () => this.loadScene.emit(unity.sceneName[0]),
            100
          );
        } else {
          this.props.setLoader(true);
          this.onSceneLoaded.call(this, unity.sceneName[0], nextProps);
        }
        this.setState({
          moreInfo: stage.moreInfo,
          title: stage.type === "unity" ? stage.title : null,
          next: stage.type === "unity" ? stage.next : null,
          baseOffset: stage.type === "unity" ? stage.offset : 0
        });
      }
    }
    if (
      nextProps.currentSection !== this.props.currentSection ||
      nextProps.currentGroup !== this.props.currentGroup
    ) {
      console.log(`No Unity for ${this.props.currentSection}`);
      if (this.props.currentSection !== 1) {
        this.props.setLoader(false);
      }
      this.loadScene.emit("Empty");
      this.setState(initialState);
      console.error("Resetting to empty scene");
    }
  }

  onProgress(progression) {
    this.props.setUnityProgress(Math.ceil(progression * 100));
    if (progression === 1) {
      this.props.setLoader(false);
    }
  }

  onSceneLoaded(sceneName, nextProps) {
    nextProps = nextProps || this.props;
    window.lastScene = sceneName;
    const stage =
      nextProps.data.sections[nextProps.currentSection] &&
      nextProps.data.sections[nextProps.currentSection].groups[
        nextProps.currentGroup
      ] &&
      nextProps.data.sections[nextProps.currentSection].groups[
        nextProps.currentGroup
      ].stages[nextProps.currentStage] &&
      nextProps.data.sections[nextProps.currentSection].groups[
        nextProps.currentGroup
      ].stages[nextProps.currentStage];
    const unity = stage && stage.unity;
    if (unity) {
      if (sceneName === unity.sceneName[0]) {
        this.props.setLoader(false);
        this.props.setUnityDisplay(true);
        this.loaded = true;
        if (unity.product) {
          if (window.lastProduct !== unity.product) {
            console.log("Setting active product", unity.product);
            this.setActiveProduct.emit("" + unity.product);
            window.lastProduct = unity.product;
          }
        }
        this.setState({
          moreInfo: stage.moreInfo,
          title: stage.type === "unity" ? stage.title : null,
          next: stage.type === "unity" ? stage.next : null,
          baseOffset: stage.type === "unity" ? stage.offset : 0
        });
      }
      if (sceneName === "Empty" && sceneName !== unity.sceneName[0]) {
        this.loadScene.emit(unity.sceneName[0]);
      }
    } else {
      this.props.setLoader(false);
    }
  }

  toggleOffset(isOpen) {
    console.log("this.state.baseOffset", this.state.baseOffset);
    this.setOffset.emit(isOpen ? "-200" : "0");
  }

  handleProgression(next) {
    if (next.hasOwnProperty("group")) {
      this.props.goToGroup(next.group);
    } else if (next.hasOwnProperty("section")) {
      this.props.goToSection(next.section);
      this.props.goToGroup(0);
    }
  }

  handleExit() {
    this.props.setUnityDisplay(false);
    this.loadScene.emit("Empty");
    this.props.goToGroup(null);
    this.props.goToStage(null);
    this.setState(initialState);
  }

  handleOperate() {
    this.setCameraPoint.emit(0);
    this.playTimeline.emit("");
  }

  render() {
    const nextProps = this.props;
    const stages =
      nextProps.data.sections[nextProps.currentSection] &&
      nextProps.data.sections[nextProps.currentSection].groups[
        nextProps.currentGroup
      ] &&
      nextProps.data.sections[nextProps.currentSection].groups[
        nextProps.currentGroup
      ].stages;
    //console.log('rerendering UnityContent', nextProps);
    return (
      <section
        ref="outerSection"
        className={`unityContent showing ${
          this.props.unityDisplay ? "showing" : ""
        }`}
      >
        {this.state.title && <Header title={this.state.title} />}
        <Unity
          src="assets/Unity/Build/Builds.json"
          loader="assets/Unity/Build/UnityLoader.js"
          onProgress={this.onProgress}
        />
        {this.state.next && stages && stages.length && (
          <section className="stageTracker">
            <button
              className="stageTracker__arrow nextProduct__arrow down"
              onClick={this.handleProgression.bind(this, this.state.next)}
            >
              <section className="stageTracker__progress">
                <img
                  className="nextProduct__arrowIcon red"
                  src={icons.arrowDown.svg}
                  alt="arrow"
                />
                <div className="stageTracker__progressTextContainer show">
                  <p className="stageTracker__progressText grey">
                    Next{" "}
                    {this.state.next.hasOwnProperty("group")
                      ? "Product"
                      : "Section"}
                    :{" "}
                  </p>
                  <p className="stageTracker__progressText black">
                    {this.state.next.text}
                  </p>
                </div>
              </section>
            </button>
          </section>
        )}
        {nextProps.currentSection !== 0 && (
          <Button
            text="Back"
            type={"back " + (this.props.unityDisplay ? "showing" : "hidden")}
            onClick={() => this.handleExit()}
          />
        )}
        {nextProps.currentSection === 1 && (
          <Button
            type={"operate-button active"}
            onClick={() => this.handleOperate()}
          >
            <span>Operate</span>
          </Button>
        )}
        {this.state.moreInfo && (
          <MoreInfo data={this.state.moreInfo} onToggle={this.toggleOffset} />
        )}
      </section>
    );
  }
}

function mapStateToProps(state) {
  return {
    currentSection: state.navigation.currentSection,
    currentGroup: state.navigation.currentGroup,
    currentStage: state.navigation.currentStage,
    unitySceneCalled: state.navigation.unitySceneCalled,
    unityDisplay: state.navigation.unityDisplay,
    loader: state.navigation.loader
  };
}

function mapDispatchToProps(dispatch) {
  return {
    setLoader: bool => {
      dispatch(actions.setLoader(bool));
    },
    setModal: index => {
      dispatch(actions.setModal(index));
    },
    goToGroup: id => {
      dispatch(actions.goToGroup(id));
    },
    goToSection: id => {
      dispatch(actions.goToSection(id));
    },
    goToStage: id => {
      dispatch(actions.goToStage(id));
    },
    setUnityDisplay: bool => {
      dispatch(actions.setUnityDisplay(bool));
    },
    setUnityProgress: percentage => {
      dispatch(actions.setUnityProgress(percentage));
    }
  };
}

UnityContent.propTypes = {
  setLoader: propTypes.func.isRequired,
  setUnityDisplay: propTypes.func.isRequired,
  unitySceneCalled: propTypes.number.isRequired,
  setUnityProgress: propTypes.func.isRequired,
  data: propTypes.object.isRequired,
  currentStage: propTypes.number,
  currentGroup: propTypes.number,
  currentSection: propTypes.number.isRequired,
  unityDisplay: propTypes.bool.isRequired
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UnityContent);
