import React from "react";
import { connect } from "react-redux";
import propTypes from "prop-types";

import icons from "../../data/icons";
import * as actions from "../../actions/index";
import data from "../../data/app.json";

import "./StageTracker.scss";

class StageTracker extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showProgressText: false
    };
    this.handleMoveDown = this.handleMoveDown.bind(this);
    this.handleMoveUp = this.handleMoveUp.bind(this);
  }

  componentWillMount() {
    // fixes bug whereby text disappears after case study
    if (this.props.canProgress) {
      this.setState({
        showProgressText: true
      });
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.canProgress) {
      const timeout = setTimeout(() => {
        this.setState({
          showProgressText: true
        });
        clearTimeout(timeout);
      }, 750);
    }
  }

  handleMoveUp() {
    if (this.props.currentStage) {
      this.props.goToStage(this.props.currentStage - 1);
    }
  }

  handleMoveDown() {
    if (this.props.currentStage < this.props.stages.length - 1) {
      this.props.goToStage(this.props.currentStage + 1);
    }
  }

  render() {
    const stage =
      data.sections[this.props.currentSection].groups[this.props.currentGroup]
        .stages[this.props.currentStage];
    const next = stage && stage.next;

    return (
      <section className={"stageTracker " + (stage.moreInfo ? "hideBars" : "")}>
        <button
          className={
            "stageTracker__arrow up " +
            (this.props.stages.length === 1 ? "hidden" : "")
          }
          onClick={this.handleMoveUp}
        >
          <img
            className={`stageTracker__arrowIcon ${
              this.props.currentStage === 0 ? "disabled" : "grey"
            }`}
            src={icons.arrowDown.svg}
            alt="arrow"
          />
          <img
            className={`stageTracker__arrowIcon ${
              this.props.currentStage === 0 ? "disabled" : "white"
            }`}
            src={icons.arrowDown.svg}
            alt="arrow"
          />
        </button>
        {this.props.stages.map((item, i) => (
          <div
            key={i}
            className={
              "stageTracker__line " +
              (this.props.stages.length === 1 ? "hidden" : "")
            }
            style={{ height: `${95 / this.props.stages.length}%` }}
          >
            <div
              className={`stageTracker__highlight ${
                this.props.currentStage >= i ? "on" : "off"
              }`}
            />
          </div>
        ))}
        <button
          className="stageTracker__arrow down"
          onClick={
            !this.props.canProgress
              ? () => this.handleMoveDown()
              : () => this.props.handleProgression(next)
          }
        >
          {!this.props.canProgress && (
            <img
              className="stageTracker__arrowIcon grey"
              src={icons.arrowDown.svg}
              alt="arrow"
            />
          )}
          {!this.props.canProgress && (
            <img
              className="stageTracker__arrowIcon white"
              src={icons.arrowDown.svg}
              alt="arrow"
            />
          )}
          {this.props.canProgress && next && (
            <section className="stageTracker__progress">
              <img
                className="stageTracker__arrowIcon red"
                src={icons.arrowDown.svg}
                alt="arrow"
              />
              <div
                className={`stageTracker__progressTextContainer ${
                  this.state.showProgressText ? "show" : "hide"
                }`}
              >
                <p className="stageTracker__progressText grey">
                  Next {next.section ? "Section" : "Product"}:
                </p>
                <p className="stageTracker__progressText black">{next.text}</p>
              </div>
            </section>
          )}
        </button>
      </section>
    );
  }
}

function mapStateToProps(state) {
  return {
    currentSection: state.navigation.currentSection,
    currentGroup: state.navigation.currentGroup,
    currentStage: state.navigation.currentStage
  };
}

function mapDispatchToProps(dispatch) {
  return {
    goToStage: stageID => {
      dispatch(actions.goToStage(stageID));
    },
    goToGroup: groupID => {
      dispatch(actions.goToGroup(groupID));
    }
  };
}

StageTracker.propTypes = {
  currentStage: propTypes.number.isRequired,
  currentGroup: propTypes.number.isRequired,
  currentSection: propTypes.number.isRequired,
  stages: propTypes.array.isRequired,
  goToStage: propTypes.func.isRequired,
  canProgress: propTypes.bool.isRequired,
  handleProgression: propTypes.func.isRequired
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(StageTracker);
