import * as types from '../actions/types';

export const initialState = {
	currentSection: 0,
	currentGroup: 0,
	currentStage: 0,
	modal: null,
	overlay: true,
	loader: false,
	unityDisplay: false,
	unityProgress: 0,
	unitySceneCalled: 0,
	onboarding: true,
};

export default function (prevState = initialState, action) {
	switch (action.type) {
		case types.GO_TO_SECTION:
			if (action.currentSection !== 1) {
				return Object.assign({}, prevState, {
					currentSection: action.currentSection,
					currentGroup: 0,
					currentStage: 0,
					overlay: true,
					unityDisplay: false,
				});
			} else {
				return Object.assign({}, prevState, {
					currentSection: action.currentSection,
					currentGroup: null,
					currentStage: null,
					overlay: true,
					unityDisplay: false,
				});
			}
		case types.GO_TO_GROUP:
			return Object.assign({}, prevState, {
				currentGroup: action.currentGroup,
				currentStage: 0,
			});
		case types.GO_TO_STAGE:
			return Object.assign({}, prevState, {
				currentStage: action.currentStage,
			});
		case types.SET_MODAL:
			return Object.assign({}, prevState, {
				modal: action.modal,
			});
		case types.SET_MEDIAMODAL:
			return Object.assign({}, prevState, {
				media: action.media,
			});
		case types.SET_OVERLAY:
			return Object.assign({}, prevState, {
				overlay: action.overlay,
			});
		case types.SET_UNITY_DISPLAY:
			return Object.assign({}, prevState, {
				unityDisplay: action.unityDisplay,
			});
		case types.SET_UNITY_PROGRESS:
			return Object.assign({}, prevState, {
				unityProgress: action.unityProgress,
			});
		case types.CALL_UNITY_SCENE:
			return Object.assign({}, prevState, {
				unitySceneCalled: action.unitySceneCalled,
			});
		case types.SET_LOADER:
			return Object.assign({}, prevState, {
				loader: action.loader,
			});
		case types.TURN_OFF_ONBOARDING:
			return Object.assign({}, prevState, {
				onboarding: false,
			});
		default:
			return prevState;
	}
}
