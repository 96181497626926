import React from 'react';
import propTypes from 'prop-types';
import { connect } from 'react-redux';

import NavBox from '../NavBox/NavBox';
import Header from '../Header/Header';
import Standard from '../Standard/Standard';
import Button from '../Button/Button';

import * as actions from '../../actions/index';
import caseStudyData from '../../data/caseStudies.json';

import './CaseStudies.scss';

class CaseStudies extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      caseStudy: null,
    };
  }

  openCaseStudy(index) {
    this.setState({
      caseStudy: index,
    });
    this.props.setOverlay(false);
  }

  closeCaseStudy() {
    this.setState({
      caseStudy: null,
    });
    this.props.setOverlay(true);
  }

  render() {
    const caseStudies = caseStudyData[this.props.data.caseStudy];
    return (
      <section className={`caseStudies ${this.state.caseStudy !== null ? 'open' : 'closed'}`}>
        <Header title={this.props.data.title} />
        <section className="caseStudies__navArea">
          {
            caseStudies.map((caseStudy, i) => {
              return <NavBox key={caseStudy.id} image={caseStudy.image} description={caseStudy.description} title={caseStudy.navTitle} subtitle={caseStudy.navSubTitle} type={`caseStudy ${this.props.data.smallRightMargin ? 'smallRightMargin' : ''}`} width={`${100 / caseStudies.length}%`} onClick={() => this.openCaseStudy(i)} isLargeBox />;
            })
          }
        </section>
        {
          this.state.caseStudy !== null &&
          <Standard
            data={caseStudyData[this.props.data.caseStudy][this.state.caseStudy]}
            caseStudyPanel={caseStudyData[this.props.data.caseStudy][this.state.caseStudy].panel}
          />
        }
        {
          this.state.caseStudy !== null &&
          <Button
            text="close"
            type="close"
            onClick={() => this.closeCaseStudy()}
          />
        }
      </section>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    setOverlay: (bool) => {
      dispatch(actions.setOverlay(bool));
    },
  };
}

function mapStateToProps(state) {
  return {
    overlay: state.navigation.overlay,
  };
}

CaseStudies.propTypes = {
  data: propTypes.object.isRequired,
  setOverlay: propTypes.func.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(CaseStudies);
