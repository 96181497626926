export function getPageNames(currentSection, appData) {
	return appData.sections[currentSection].stages.map((stage) => {
		return {
			label: stage.title,
			id: stage.id,
		};
	});
}

export function getSectionNames(appData) {
	return appData.sections.map((section) => {
		return {
			label: section.name,
			id: section.id,
			navIcon: section.navIcon,
			subNav: section.subNav,
		};
	});
}
