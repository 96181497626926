import React, { Component } from 'react';
import { connect } from 'react-redux';
import propTypes from 'prop-types';

import Standard from '../Standard/Standard';
import Timeline from '../Timeline/Timeline';
import Flexible from '../Flexible/Flexible';
import Wheel from '../Wheel/Wheel';
import FullScreen from '../FullScreen/FullScreen';
import UnityDisplay from '../UnityDisplay/UnityDisplay';
import Intro from '../Intro/Intro';
import CaseStudies from '../CaseStudies/CaseStudies';
import Home from '../Home/Home';
import Button from '../Button/Button';

import * as actions from '../../actions/index';

import './Stage.scss';

class Stage extends Component {
	constructor(props) {
		super(props);
		this.goBack = this.goBack.bind(this);
	}

	goBack() {
		this.props.goToSection(this.props.currentSection);
	}

	render() {
		return (
			<section
				className={`stage stage--${this.props.data.type} ${(this.props.data.hasDarkBackground ? 'overdark' : 'overlight')}`}
				id={this.props.data.id}
			>
				{
					this.props.data.overlayColor &&
					<section className="fullOverlay" style={{backgroundColor: this.props.data.overlayColor}}>
					</section>
				}
				{
					this.props.data.type === 'fullscreen' &&
					<FullScreen data={this.props.data} />
				}
				{
					this.props.data.type === 'home' &&
					<Home />
				}
				{
					this.props.data.type === 'caseStudies' &&
					<CaseStudies data={this.props.data} />
				}
				{
					this.props.data.type === 'intro' &&
					<Intro data={this.props.data} />
				}
				{
					this.props.data.type === 'standard' &&
					<Standard data={this.props.data} />
				}
				{
					this.props.data.type === 'unity' &&
					<UnityDisplay data={this.props.data} />
				}
				{
					this.props.data.type === 'flexible' &&
					<Flexible />
				}
				{
					this.props.data.type === 'timeline' &&
					<Timeline data={this.props.data.timeline} title={this.props.data.title} />
				}
				{
					this.props.data.type === 'wheel' &&
					<Wheel />
				}
				{
					this.props.currentSection === 1 && !(this.props.currentStage === 1 && !this.props.overlay) &&
					<Button
						type="back"
						text="Back"
						onClick={this.goBack}
					/>
				}
			</section>
		);
	}
}


function mapStateToProps(state) {
	return {
		overlay: state.navigation.overlay,
		currentStage: state.navigation.currentStage,
	};
}

function mapDispatchToProps(dispatch) {
	return {
		goToSection: (sectionID) => {
			dispatch(actions.goToSection(sectionID));
		},
	};
}


Stage.propTypes = {
	data: propTypes.shape({
		title: propTypes.string,
		subtitle: propTypes.string,
		type: propTypes.string.isRequired,
		//id: propTypes.string.isRequired,
		video: propTypes.object,
		imageSequence: propTypes.object,
		images: propTypes.array,
	}),
	currentSection: propTypes.number.isRequired,
	currentStage: propTypes.number.isRequired,
	goToSection: propTypes.func.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(Stage);
