import React from 'react';
import { connect } from 'react-redux';
import propTypes from 'prop-types';
import Button from '../Button/Button';
import Panel from '../Panel/Panel';
import TechData from '../TechData/TechData';

import icons from '../../data/icons';
import * as actions from '../../actions/index';

import './MoreInfo.scss';

const toggleOpen = function() {
  this.setState({ open: !this.state.open });
  if(this.props.onToggle) {
    this.props.onToggle(!this.state.open);
  }
};

const menuItems = [
  {
    name: 'overview',
    text: 'Overview',
  },
  {
    name: 'techData',
    text: 'Tech Data',
  },
  {
    name: 'media',
    text: 'Media Library',
  },
];

const setVisiblePanel = function(panelName) {
  this.setState({ visiblePanel: panelName });
}

const openMediaModal = function(media) {
  // Open media in media modal
  this.props.setMediaModal(media);
}

class MoreInfo extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      visiblePanel: 'overview'
    }
  }
  render() {
    const props = this.props;
    return (
      <section className={"moreInfo " + (this.state.open ? 'open' : '')}>
        <Button
          type={"moreInfo-button " + (this.state.open ? 'active' : 'inactive')}
          onClick={toggleOpen.bind(this)}>
            <span>{this.state.open ? 'Close' : 'More Info'}</span>
        </Button>
        
        <section className="moreInfo-panel">
          <section className="moreInfo-menu">
            {menuItems.map((item, i) => {
              return (
                <div
                  key={"moreInfo-menuItem"+i}
                  className={"moreInfo-menuItem " + (this.state.visiblePanel === item.name ? 'active' : 'inactive')}
                  onClick={setVisiblePanel.bind(this, item.name)}>
                  <span>{item.text}</span>
                </div>
              );
            })}
          </section>
          <section className="moreInfo-content">
            {
              this.state.visiblePanel === 'overview' &&
              <section className="moreInfo__overview">
                <Panel panel={props.data.overview.panel} />
              </section>
            }
            {
              this.state.visiblePanel === 'techData' &&
              <section className="moreInfo__techData">
                <TechData data={props.data.techData.data} />
              </section>
            }
            {
              this.state.visiblePanel === 'media' &&
              <section className="moreInfo__media">
                {props.data.media.map((media, i) => {
                  return (
                    <section 
                      key={"moreInfo__mediaRow-"+i}
                      className="moreInfo__mediaRow"
                      onClick={openMediaModal.bind(this, media)}>
                      <img className="mediaIcon" src={icons[media.type==="movie" ? 'movie' : 'doc']['svg']} alt="" />
                      <img className="arrow" src={icons['arrowRed']['svg']} alt="" />
                      <div className="mediaTitle">{media.title}</div>
                    </section>
                  );
                })}
              </section>
            }
          </section>
        </section>
      </section>
    )
  }
}

MoreInfo.propTypes = {
  data: propTypes.object.isRequired,
  setMediaModal: propTypes.func.isRequired,
  onToggle: propTypes.func,
};

function mapDispatchToProps(dispatch) {
  return {
    setMediaModal: (media) => {
      dispatch(actions.setMediaModal(media));
    },
  };
}

export default connect(null, mapDispatchToProps)(MoreInfo);