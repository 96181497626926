import React from 'react';
import { connect } from 'react-redux';
import propTypes from 'prop-types';

import icons from '../../data/icons';
import * as actions from '../../actions/index';

import './ContentLinkArea.scss';

const ContentLinkArea = props => (
  <section className="contentLinkArea">
    {
      props.data.map((link, pos) => (
        <section key={link.id || ("link"+pos)} className="contentLinkArea__link" onClick={props.setMediaModal.bind(this, link)}>
          <img
            className="contentLinkArea__image"
            src={icons[link.icon].svg}
            alt={link.icon}
          />
          <span className="contentLinkArea__text">
            {link.text}
          </span>
          <img
            className="contentLinkArea__arrow"
            src={icons.arrowDown.svg}
            alt={link.icon}
          />
        </section>
      ))
    }
  </section>
);

ContentLinkArea.propTypes = {
  data: propTypes.array.isRequired,
};

function mapDispatchToProps(dispatch) {
  return {
    setMediaModal: (media) => {
      dispatch(actions.setMediaModal(media));
    },
  };
}

export default connect(null, mapDispatchToProps)(ContentLinkArea);

