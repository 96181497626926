import React from "react";
import { connect } from "react-redux";
import propTypes from "prop-types";
import Transition from "react-transition-group/Transition";
import TransitionGroup from "react-transition-group/TransitionGroup";

import * as actions from "../../actions/index";
import icons from "../../data/icons";

import Overlay from "../Overlay/Overlay";
import Button from "../Button/Button";

import "./Timeline.scss";

class Timeline extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      currentPage: 0,
      animating: true
    };
    this.pageTotal = Math.ceil(this.props.data.length / 3);
    this.toggleOverlay = this.toggleOverlay.bind(this);
  }

  componentWillUnmount() {
    this.props.setOverlay(true);
  }

  handleKenBurns() {
    this.setState({
      animating: false
    });
    setImmediate(() => {
      this.setState({
        animating: true
      });
    });
  }

  moveTimeline(isMovingLeft) {
    if (isMovingLeft && this.state.currentPage > 0) {
      this.setState({
        currentPage: this.state.currentPage - 1
      });
    }
    if (!isMovingLeft && this.state.currentPage < this.pageTotal - 1) {
      this.setState({
        currentPage: this.state.currentPage + 1
      });
    }
    this.handleKenBurns();
  }

  toggleOverlay() {
    this.props.setOverlay(!this.props.overlay);
  }

  render() {
    return (
      <section
        className="timeline"
        style={{
          left: `-${this.state.currentPage * 100}%`,
          width: `${this.pageTotal * 100}%`
        }}
      >
        <TransitionGroup>
          {this.props.overlay && (
            <Transition timeout={500}>
              {transitionState => (
                <Overlay
                  transitionState={transitionState}
                  icon="timeline"
                  onClick={this.toggleOverlay}
                  title="Our outstanding track record of innovation"
                  iconText="Interactive timeline"
                  left={`${(100 / this.pageTotal) * this.state.currentPage}%`}
                />
              )}
            </Transition>
          )}
        </TransitionGroup>
        {this.props.data.map((column, i) => (
          <section key={i} className="timeline__column">
            {column.map((item, i) => (
              <section
                key={item.id}
                className={`timeline__item ${i > 0 ? "inversePadding" : ""}`}
                style={{
                  backgroundColor: item.backgroundColor,
                  color: item.color,
                  zIndex: item.zIndex
                }}
              >
                <h2 className="timeline__year">{item.year}</h2>
                <p className="timeline__text">{item.text}</p>
                {item.image && (
                  <img
                    className="timeline__image"
                    src={item.image.src}
                    alt={item.image.alt}
                  />
                )}
                {item.video && (
                  <video
                    className={`timeline__video ${item.video.style}`}
                    loop
                    autoPlay
                    muted
                    style={{
                      objectFit: item.video.fit,
                      zIndex: `${item.video.zIndex ? item.video.zIndex : ""}`
                    }}
                    src={item.video.src}
                    type="video/mp4"
                  />
                )}
                {item.video && item.video.overlay && (
                  <div
                    className="timeline__videoOverlay"
                    style={{ backgroundColor: item.video.overlay }}
                  />
                )}
                {item.backgroundImage && (
                  <img
                    className={`timeline__backgroundImage ${
                      this.state.animating ? "animating" : ""
                    } ${item.backgroundImage.delay ? "delay" : ""}`}
                    src={item.backgroundImage.src}
                    alt={item.backgroundImage.alt}
                  />
                )}
                {item.backgroundImage && item.backgroundImage.overlay && (
                  <div
                    className="timeline__backgroundImageOverlay"
                    style={{ backgroundColor: item.backgroundImage.overlay }}
                  />
                )}
              </section>
            ))}
          </section>
        ))}
        <TransitionGroup>
          {!this.props.overlay && (
            <Transition timeout={500}>
              {transitionState => (
                <nav className={`timeline__nav ${transitionState}`}>
                  {this.state.currentPage === 0 && (
                    <button
                      className="unityContent__button"
                      onClick={() => this.moveTimeline(true)}
                    >
                      <img
                        src={icons.arrowDown.svg}
                        alt="move unityContent"
                        className="unityContent__icon left alt"
                      />
                    </button>
                  )}
                  {!(this.state.currentPage === 0) && (
                    <button
                      className="unityContent__button"
                      onClick={() => this.moveTimeline(true)}
                    >
                      <img
                        src={icons.arrowDown.svg}
                        alt="move unityContent"
                        className="unityContent__icon left"
                      />
                    </button>
                  )}
                  <section className="timeline__progressBarContainer">
                    <div
                      style={{
                        width: `${((this.state.currentPage + 1) * 100) /
                          this.pageTotal}%`
                      }}
                      className="timeline__progressBar"
                    />
                  </section>
                  {this.state.currentPage !== this.pageTotal - 1 && (
                    <button
                      className="unityContent__button"
                      onClick={() => this.moveTimeline(false)}
                    >
                      <img
                        src={icons.arrowDown.svg}
                        alt="move unityContent"
                        className="unityContent__icon right"
                      />
                    </button>
                  )}
                  {this.state.currentPage === this.pageTotal - 1 && (
                    <button
                      className="unityContent__button"
                      onClick={() => this.moveTimeline(false)}
                    >
                      <img
                        src={icons.arrowDown.svg}
                        alt="move unityContent"
                        className="unityContent__icon right alt"
                      />
                    </button>
                  )}
                </nav>
              )}
            </Transition>
          )}
        </TransitionGroup>
        <TransitionGroup>
          {!this.props.overlay && (
            <Transition timeout={100}>
              {transitionState => (
                <div className={`timeline__back ${transitionState}`}>
                  <Button
                    text="close"
                    type="close"
                    onClick={() => this.toggleOverlay()}
                  />
                </div>
              )}
            </Transition>
          )}
        </TransitionGroup>
      </section>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    setOverlay: bool => {
      dispatch(actions.setOverlay(bool));
    }
  };
}

function mapStateToProps(state) {
  return {
    overlay: state.navigation.overlay
  };
}

Timeline.propTypes = {
  data: propTypes.array.isRequired,
  overlay: propTypes.bool.isRequired,
  setOverlay: propTypes.func.isRequired
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Timeline);
