import React from "react";
// import ReactDOM from 'react-dom';
import propTypes from "prop-types";
import { connect } from "react-redux";

import FullHeader from "../FullHeader/FullHeader";

import * as actions from "../../actions/index";

import "./VideoOverlay.scss";

//This function may be used to try and determine the logical positioning of the
//  video element when in object-fit: cover (or contain), so buttons can be
//  manually positioned relative to the video, usage is shown in componentDidMount.
//  Have left it in as it gives a pretty close result, but still needs some work
//  If buttons need to be pixel-perfect, either use this, and finesse, or refactor
//  this component entirely so object-fit is not used.
//  At the moment this is done by %-positioning with the top and left stored in app.json
//
function getRenderedSize(contains, cWidth, cHeight, width, height, pos) {
  var oRatio = width / height,
    cRatio = cWidth / cHeight;
  return function() {
    if (contains ? oRatio > cRatio : oRatio < cRatio) {
      this.width = cWidth;
      this.height = cWidth / oRatio;
    } else {
      this.width = cHeight * oRatio;
      this.height = cHeight;
    }
    this.left = (cWidth - this.width) * (pos / 100);
    this.right = this.width + this.left;
    this.top = (cHeight - this.height) * (pos / 100);
    this.bottom = this.height + this.top;
    return this;
  }.call({});
}

class VideoOverlay extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      fading: false,
      buttonShowing: true
    };
    this.callUnityScene = this.callUnityScene.bind(this);
    this.updateButtonStyles = this.updateButtonStyles.bind(this);
  }

  updateButtonStyles() {
    const buttons = this.props.data.video.buttons || [];

    const videoContainer = this.refs.videoContainer;
    const video = this.refs.video;

    const rendered = getRenderedSize(
      false,
      videoContainer.offsetWidth,
      videoContainer.offsetHeight,
      video.videoWidth,
      video.videoHeight,
      50
    );

    this.setState({
      buttonStyles: buttons.map((btn, pos) => {
        return {
          //Has some magic-numbers.
          left:
            ((rendered.width + rendered.left * 1.2) / 100) * btn.left + "px",
          top: ((rendered.height + rendered.top * 1.28) / 100) * btn.top + "px",
          width: (videoContainer.offsetWidth / 100) * 12
        };
      })
    });
  }

  componentDidMount(props) {
    // console.log('props', props, this);
    this.refs.video.removeEventListener("loadeddata", this.updateButtonStyles);
    window.removeEventListener("resize", this.updateButtonStyles);

    this.refs.video.addEventListener("loadeddata", this.updateButtonStyles);
    window.addEventListener("resize", this.updateButtonStyles);

    this.timeout = setTimeout(() => {
      this.setState({
        buttonShowing: true
      });

      this.updateButtonStyles();

      clearTimeout(this.timeout);
    }, this.props.data.video.length);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.currentSection !== this.props.currentSection) {
      this.forceUpdate();
    }
  }

  componentWillUnmount() {
    clearTimeout(this.timeout);

    this.refs.video.removeEventListener("loadeddata", this.updateButtonStyles);
    window.removeEventListener("resize", this.updateButtonStyles);
  }

  callUnityScene(num) {
    this.props.callUnityScene(num);

    const timeout = setTimeout(() => {
      this.props.callUnityScene(false);
      clearTimeout(timeout);
    }, 500);
  }

  render() {
    const item = this.props.data;
    return (
      <section className="videoOverlay">
        <FullHeader
          title={this.props.data.title}
          subtitle={this.props.data.subtitle}
          type="white absolute"
        />
        <p
          className={`videoOverlay__body ${
            this.props.data.subtitle ? "" : "noSubTitle"
          }`}
        >
          {this.props.data.body}
        </p>
        <section ref="videoContainer" className="videoOverlay__imageArea">
          <video
            ref="video"
            className={`videoOverlay__video ${item.video.style || ""} ${
              this.state.fading ? "fading" : ""
            }`}
            autoPlay
            muted
            loop={item.video.loop}
            style={{
              objectFit: item.video.fit,
              zIndex: `${item.video.zIndex ? item.video.zIndex : ""}`
            }}
            src={item.video.src}
            type="video/mp4"
          />
          <section className="buttonContainer">
            {this.state.buttonShowing &&
              item.video.buttons &&
              item.video.buttons.map((btn, pos) => {
                return (
                  <button
                    key={"unityButton" + pos}
                    className="overlay__button video-overlay"
                    onClick={() => this.callUnityScene(pos + 1)}
                  >
                    {btn.text}
                  </button>
                );
              })}
          </section>
        </section>
      </section>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    callUnityScene: num => {
      dispatch(actions.callUnityScene(num));
    }
  };
}

function mapStateToProps(state) {
  return {
    fading: state.fading,
    currentSection: state.navigation.currentSection
  };
}

VideoOverlay.propTypes = {
  data: propTypes.object.isRequired,
  callUnityScene: propTypes.func.isRequired,
  fading: propTypes.bool,
  currentSection: propTypes.number.isRequired
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(VideoOverlay);
