import React from "react";
import propTypes from "prop-types";
import { connect } from "react-redux";
import Logo from "../../assets/images/Hitachi_Energy.svg";
import LogoNext from "../../assets/images/Hitachi_Logo.svg";
import icons from "../../data/icons";
import "./LoadScreen.scss";

class LoadScreen extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showConnectionWarning: false,
      showPerformanceWarning: false
    };
  }
  componentDidMount() {
    if (this.props && this.props.showConnectionWarning) {
      this.connectionTimeout = setTimeout(
        () => this.setState({ showConnectionWarning: true }),
        10 * 1000
      );
    }
    if (this.props.currentSection === 0) {
      this.performanceTimeout = setTimeout(
        () => this.setState({ showPerformanceWarning: true }),
        20 * 1000
      );
    }
  }
  componentWillUnmount() {
    if (this.connectionTimeout) {
      clearTimeout(this.connectionTimeout);
    }
    if (this.performanceTimeout) {
      clearTimeout(this.performanceTimeout);
    }
  }
  render() {
    const props = this.props;
    return (
      <section className={`loadScreen ${props.currentSection === 1 ? "grey" : props.currentSection !== 0 ? "alternative" : ""}`}>
        <div className="loadScreen__logo_wrapper">
          <img className="loadScreen__logo" src={LogoNext} alt="hitachi logo" />
          <img className="loadScreen__logo" src={Logo} alt="hitachi logo" />
        </div>
        <section className="loadScreen__mainArea">
          <div className="loadScreen__spinner_wrapper">
            <div className="loadScreen__spinner"></div>
            <img
              className="loadScreen__icon"
              src={icons.disconnectors.svg}
              alt="disconnectors icon"
            />
          </div>
          {props.currentSection === 0 && props.currentStage === 0 && (
            <div className="loadScreen__additionalInfo">
              <h1 className="loadScreen__title">Disconnectors</h1>
              <h3 className="loadScreen__text">App is loading, please wait...</h3>
              <h4 className="loadScreen__percentage">{`${props.unityProgress}%`}</h4>
            </div>
          )}
          {this.state.showConnectionWarning && (
            <section className="connectionWarning">
              You may not be connected to the internet.
            </section>
          )}
        </section>
        {this.state.showPerformanceWarning && (
          <section className="loadScreen__performanceWarning">
            Performance may not be optimal on older systems
          </section>
        )}
      </section>
    );
  }
}

function mapStateToProps(state) {
  return {
    unityProgress: state.navigation.unityProgress,
    currentSection: state.navigation.currentSection,
    currentStage: state.navigation.currentStage
  };
}

LoadScreen.propTypes = {
  unityProgress: propTypes.number.isRequired,
  currentSection: propTypes.number.isRequired,
  showConnectionWarning: propTypes.bool
};

export default connect(mapStateToProps)(LoadScreen);
