import React from "react";
import propTypes from "prop-types";
import { connect } from "react-redux";
import Panel from "../Panel/Panel";
import Header from "../Header/Header";
import Button from "../Button/Button";
import TextDisplay from "../TextDisplay/TextDisplay";
import UnityDisplay from "../UnityDisplay/UnityDisplay";
import Home from "../Home/Home";

import icons from "../../data/icons";
import * as actions from "../../actions/index";
import "./Standard.scss";

const setActivePanel = function(newPanel) {
  this.setState({
    panel: newPanel
    // imageFade: false
  });
  // clearTimeout(this.timeout);
  // this.timeout = setTimeout(() => this.setState({ imageFade: true }), 200);
};

class Standard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      panel: props.panel,
      visible: true,
      imageFade: false
    };
  }
  componentWillUnmount() {
    clearTimeout(this.timeout);
  }
  componentWillReceiveProps(nextProps) {
    this.setState({ visible: false, imageFade: true });
    clearTimeout(this.timeout);
    this.timeout = setTimeout(
      () => this.setState({ visible: true, imageFade: false }),
      100
    );
  }
  render() {
    const props = this.props;
    const state = this.state;
    return (
      <section>
        {this.props.onboarding && <Home />}
        <section
          className={`standard ${props.data.fullscreen ? "fullscreen" : ""} ${
            props.data.style ? props.data.style : ""
          }`}
        >
          <section
            className={`standard__lhs ${
              props.data.style ? props.data.style : ""
            } ${state.visible ? "visible" : "invisible"}`}
          >
            {props.data.titleicons && (
              <section className="standard__icons">
                {props.data.titleicons.map((icon, i) => {
                  const iconSrc = (icons[icon] && icons[icon]["svg"]) || icon;
                  return (
                    <img
                      className="standard__icon"
                      key={"standard__icon-" + i}
                      src={iconSrc}
                      alt={icon}
                    />
                  );
                })}
              </section>
            )}
            {(props.data.title ||
              props.data.subtitle ||
              props.data.pretitle) && (
              <Header
                title={props.data.title}
                subtitle={props.data.subtitle}
                pretitle={props.data.pretitle}
                width={props.data.headerWidth}
              />
            )}
            <Panel
              caseStudyPanel={props.caseStudyPanel}
              panel={this.state.panel || props.data.panel}
            />
          </section>
          <section
            className={`standard__rhs ${
              props.data.style ? props.data.style : ""
            }`}
          >
            {props.data.video && (
              <video
                className={`standard__video ${
                  props.data.video.style ? props.data.video.style : ""
                }`}
                loop={props.data.video.loop}
                autoPlay
                muted
                style={{ objectFit: props.data.video.fit }}
                src={props.data.video.src}
                type="video/mp4"
              />
            )}
            {props.data.image && (
              <img
                className={`standard__image ${
                  props.data.image.fit ? props.data.image.fit : ""
                } ${props.data.style ? props.data.style : ""} ${
                  props.data.hasDarkBackground ? "dark" : ""
                } ${this.state.imageFade ? "invisible" : "visible"}`}
                src={props.data.image.src}
                alt={props.data.image.alt}
              />
            )}
            {props.data.textDisplay && (
              <TextDisplay data={props.data.textDisplay} />
            )}
            {props.data.hotspots &&
              props.data.hotspots.map((hotspot, i) => {
                return (
                  <Button
                    key={"mapButton-" + i}
                    onClick={setActivePanel.bind(this, hotspot.panel)}
                    className="standard__hotspot"
                    type={
                      "mapNav " +
                      (this.state.panel === hotspot.panel
                        ? "active"
                        : "inactive")
                    }
                    style={{ top: hotspot.top, left: hotspot.left }}
                  >
                    {hotspot.city}, <strong>{hotspot.country}</strong>
                  </Button>
                );
              })}
          </section>
          {props.data.altVideo && (
            <section className={`standard__altMediaArea ${props.data.style}`}>
              {props.data.altVideo && (
                <video
                  className={`standard__video ${
                    props.data.altVideo.type ? propTypes.data.altVideo.type : ""
                  }`}
                  loop={props.data.altVideo.loop}
                  autoPlay
                  muted
                  style={{ objectFit: props.data.altVideo.fit }}
                  src={props.data.altVideo.src}
                  type="video/mp4"
                />
              )}
            </section>
          )}
          {props.data.altImage && (
            <img
              className={`standard__altImage ${
                props.data.altImage.type ? props.data.altImage.type : ""
              }`}
              src={props.data.altImage.src}
              alt={props.data.altImage.alt}
            />
          )}
          {props.data.unity && <UnityDisplay data={this.props.data} />}
        </section>
      </section>
    );
  }
}

function mapStateToProps(state) {
  return {
    onboarding: state.navigation.onboarding
  };
}

function mapDispatchToProps(dispatch) {
  return {
    turnOffOnboarding: () => {
      dispatch(actions.turnOffOnboarding());
    }
  };
}

Standard.propTypes = {
  data: propTypes.shape({
    title: propTypes.string,
    subtitle: propTypes.string,
    pretitle: propTypes.string,
    image: propTypes.object,
    altImage: propTypes.object,
    video: propTypes.object,
    fullscreen: propTypes.bool,
    altVideo: propTypes.object,
    style: propTypes.string,
    headerWidth: propTypes.string,
    onboarding: propTypes.bool
  }),
  caseStudyPanel: propTypes.number
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Standard);
