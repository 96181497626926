import React from 'react';
import propTypes from 'prop-types';

import icons from '../../data/icons';

import './Overlay.scss';

const Overlay = props => (
  <section className={`overlay ${props.transitionState} ${props.type ? props.type : ''}`} style={{ left: props.left }}>
    <header>
      <span className="overlay__iconArea">
        <img className="overlay__icon" src={icons[props.icon].svg} alt="overlay icon" />
        {props.iconText}
      </span>
      <h2 className="overlay__title">
        {props.title}
      </h2>
    </header>
    <button
      className="overlay__button"
      onClick={() => { props.onClick(); }}
    >
      Explore
    </button>
  </section>
);

Overlay.propTypes = {
  transitionState: propTypes.string.isRequired,
  icon: propTypes.string.isRequired,
  onClick: propTypes.func.isRequired,
  title: propTypes.string,
  type: propTypes.string,
  iconText: propTypes.string,
  left: propTypes.string,
};

export default Overlay;
