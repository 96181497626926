import React from "react";
import propTypes from "prop-types";
import { connect } from "react-redux";

import Header from "../Header/Header";
import Panel from "../Panel/Panel";

import "./FullScreen.scss";

class FullScreen extends React.Component {
  componentDidMount() {
    const video = document.getElementById("video");
    if (this.props.loader && video) {
      video && video.pause();
    } else {
      video && video.play();
    }
  }

  componentWillReceiveProps(nextProps) {
    const video = document.getElementById("video");
    if (!nextProps.loader && video) {
      video && video.play();
    }
  }

  render() {
    return (
      <section
        className={`fullScreen ${
          this.props.data.style ? this.props.data.style : ""
        }`}
      >
        <section
          className={`standard__lhs ${
            this.props.data.style ? this.props.data.style : ""
          }`}
        >
          {(this.props.data.title ||
            this.props.data.subtitle ||
            this.props.data.pretitle) && (
            <Header
              title={this.props.data.title}
              subtitle={this.props.data.subtitle}
              pretitle={this.props.data.pretitle}
              type={this.props.data.style}
            />
          )}
          <Panel style={this.props.data.style} panel={this.props.data.panel} />
        </section>
        {this.props.data.image && (
          <img
            className={`fullScreen__image ${
              this.props.data.image.fit ? this.props.data.image.fit : ""
            }`}
            src={this.props.data.image.src}
            alt={this.props.data.image.alt}
          />
        )}
        {this.props.data.video && (
          <video
            id="video"
            autoPlay
            muted
            className="fullScreen__video"
            loop={this.props.data.video.loop}
            style={{ objectFit: this.props.data.video.fit }}
            src={this.props.data.video.src}
            type="video/mp4"
          />
        )}
        {this.props.data.explore && (
          <button className="fullScreen__explore">Explore</button>
        )}
      </section>
    );
  }
}

function mapStateToProps(state) {
  return {
    loader: state.navigation.loader,
    currentStage: state.navigation.currentStage
  };
}

FullScreen.propTypes = {
  data: propTypes.object.isRequired,
  loader: propTypes.bool.isRequired
};

export default connect(mapStateToProps)(FullScreen);
