import React from 'react';
import propTypes from 'prop-types';

import './TextDisplay.scss';

const TextDisplay = props => (
  <section className={"textDisplay has-"+props.data.length}>
    {
      props.data.map((container, i) => (
        <section key={container.id} className={`textDisplay__container ${i % 2 !== 0 ? 'right' : ''}`}>
          <img
            className="textDisplay__image"
            src={container.image.src}
            alt={container.image.alt}
          />
          <section className="textDisplay__textArea">
            <h2 className="textDisplay__header">
              {container.header}
            </h2>
            {
              container.list &&
              <ul className="textDisplay__list">
                {
                  container.list.title &&
                  <h3 className="textDisplay__listHeader">
                    {container.list.title}
                  </h3>
                }
                {
                  container.list.items.map(item => (
                    <li key={item.id} className="textDisplay__listItem">
                      {item.text}
                    </li>
                  ))
                }
              </ul>
              
            }
            <p className="textDisplay__text">
              {container.text}
            </p>
          </section>
        </section>
      ))
    }
  </section>
);

TextDisplay.propTypes = {
  data: propTypes.array,
};

export default TextDisplay;
