import React from 'react';
import data from '../../data/flexible.json';

import Header from '../Header/Header';
import icons from '../../data/icons';

import './Flexible.scss';

class Flexible extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      timer: 0,
    };
  }

  render() {
    return (
      <section className="flexible">
        <Header title="Flexible scope of supply" />
        <section className="flexible__top">
          {
            data.boxes.map(box => (
              <section key={box.id} className="flexible__box">
                <img
                  src={box.image.src}
                  alt={box.image.alt}
                  className="flexible__boxImage"
                />
                <section className="flexible__boxTextArea">
                  <h2 className="flexible__boxHeader">
                    {box.title}
                  </h2>
                  <ul className="flexible__boxList">
                    {
                      box.list.map(item => (
                        <li key={item.id} className="flexible__boxItem">
                          {item.text}
                        </li>
                      ))
                    }
                  </ul>
                </section>
              </section>
            ))
          }
        </section>
        <section className="flexible__bottom">
          <section className="flexible__bottomLhs">
            <h3 className="flexible__lhsText">
              Semiconductors
            </h3>
          </section>
          <section className="flexible__bottomRhs">
            <ul className="flexible__icons">
              {
                data.icons.map(item => (
                  <li key={item.id} className="flexible__item">
                    <img
                      className="flexible__itemImage"
                      alt={item.icon}
                      src={icons[item.icon].svg}
                    />
                    <section className="flexible__itemTextArea">
                      <h3 className="flexible__itemLabel">
                        {item.label}
                      </h3>
                      <h4 className="flexible__itemSubLabel">
                        {item.subLabel}
                      </h4>
                    </section>
                  </li>
                ))
              }
            </ul>
            <p className="flexible__smallPrint">
              *variants possible on procurement and depth of supervision
            </p>
          </section>
        </section>
      </section>
    );
  }
}

export default Flexible;
