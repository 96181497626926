import React from 'react';
import propTypes from 'prop-types';
import { connect } from 'react-redux';

import * as navHelpers from '../../helpers/navHelpers';
import * as actions from '../../actions/index';

import './App.scss';
import Section from '../Section/Section';
import VerticalNav from '../VerticalNav/VerticalNav';
import LoadScreen from '../LoadScreen/LoadScreen';
import Modal from '../Modal/Modal';
import MediaModal from '../MediaModal/MediaModal';
import UnityContent from '../UnityContent/UnityContent';

import appData from '../../data/app.json';

const App = (props) => {
	return (
		<main
			className={'app'}
		>
			{
				<UnityContent data={appData} />
			}
			{
				props.loader &&
				<LoadScreen />
			}

			{
				props.modal !== null &&
				<Modal />
			}

			{
				props.media && 
				<MediaModal media={props.media} />
			}

			<Section
				currentSection={props.currentSection}
				currentGroup={props.currentGroup}
				currentStage={props.currentStage}
				numSections={appData.sections.length}
			/>

			{
				appData.sections.length > 1 &&
				<VerticalNav
					title={'Section navigation'}
					navItems={navHelpers.getSectionNames(appData)}
					currentSection={props.currentSection}
					onClick={props.goToSection}
				/>
			}
		</main>
	);
}

function mapStateToProps(state) {
	return {
		currentSection: state.navigation.currentSection,
		currentGroup: state.navigation.currentGroup,
		currentStage: state.navigation.currentStage,
		modal: state.navigation.modal,
		media: state.navigation.media,
		loader: state.navigation.loader,
	};
}

function mapDispatchToProps(dispatch) {
	return {
		goToSection: (sectionID) => {
			dispatch(actions.goToSection(sectionID));
		},
		goToStage: (stageID) => {
			dispatch(actions.goToStage(stageID));
		},
		setLoader: (bool) => {
			dispatch(actions.setLoader(bool));
		}
	};
}

App.propTypes = {
	currentSection: propTypes.number.isRequired,
	currentStage: propTypes.number,
	goToStage: propTypes.func.isRequired,
	goToSection: propTypes.func.isRequired,
	setLoader: propTypes.func.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
