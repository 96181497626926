import React from 'react';
import propTypes from 'prop-types';

import './TechData.scss';

const setColumn = function(colName) {
  // console.log('Want to set column to selected', this.refs.columnSelector.value);
  
  const props = this.props;
  const keys = Object.keys(props.data[0]);

  this.setState({
    currentColumn: keys.indexOf(this.refs.columnSelector.value)
  });
};

class TechData extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      currentColumn: 2
    }
  }
  render() {
    const props = this.props;
    const keys = Object.keys(props.data[0]);
    const columnKey = keys[this.state.currentColumn];

    // console.log(props.data, keys);
    return (
      <section className="techData">
        <section className="techDataSelector">
          <select ref="columnSelector" onChange={setColumn.bind(this)} defaultValue={columnKey}>
            {keys.slice(2).map((key, pos) => (
              <option value={key} key={key}>Rated Voltage: {key}</option>
            ))}
          </select>
        </section>
        <section className="techDataRows">
          {props.data.slice(0).map((data, dataPos) => {
            if(!props.data[dataPos][columnKey]) {
              return null;
            }

            return (
              <section className="techDataRow" key={"techDataRow-"+dataPos}>
                <div className="techData-text">{props.data[dataPos].title}</div>
                <strong className="techData-value">
                  {props.data[dataPos][columnKey] + ' ' + (props.data[dataPos].unit || '')}
                </strong>
              </section>
            )
          })}
        </section>
      </section>
    );
  }
}

TechData.propTypes = {
  data: propTypes.array.isRequired,
};

export default TechData;
