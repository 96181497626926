import React, { Component } from 'react';
import { connect } from 'react-redux';
import ReactDOM from 'react-dom';
import propTypes from 'prop-types';
import Transition from 'react-transition-group/Transition';
import TransitionGroup from 'react-transition-group/TransitionGroup';

import Stage from '../Stage/Stage';
import StageTracker from '../StageTracker/StageTracker';
import MoreInfo from '../MoreInfo/MoreInfo';

import * as actions from '../../actions/index';
import icons from '../../data/icons';

import './Group.scss';

class Group extends Component {
  constructor(props) {
    super(props);
    this.state = {
      scrollAnimating: false,
      hidden: false,
    };
    this.handleSwipe = this.handleSwipe.bind(this);
    this.handleWheel = this.handleWheel.bind(this);
    this.handleProgression = this.handleProgression.bind(this);
    this.groupLength = this.props.data.stages.length;
    this.scrollWaiting = false;
  }


  componentDidMount() {
    ReactDOM.findDOMNode(this).addEventListener('onSwipe', this.handleSwipe);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.currentStage !== this.props.currentStage) {
      this.setState({
        scrollAnimating: true,
      });
      const timeout = setTimeout(() => {
        this.setState({
          scrollAnimating: false,
        });
        clearTimeout(timeout);
      }, 500);
    }
    if (nextProps.unityDisplay) {
      const timeout2 = setTimeout(() => {
        // this.setState({
        //   hidden: true,
        // });
        clearTimeout(timeout2);
      }, 1000);
    }
    if (!nextProps.unityDisplay) {
      this.setState({
        hidden: false,
      });
    }
    this.groupLength = nextProps.data.stages.length;
  }
  
  componentWillUnmount() {
    ReactDOM.findDOMNode(this).removeEventListener('onSwipe', this.handleSwipe);
  }

  setScrollWait() {
    this.scrollWaiting = true;
    const timeout = setTimeout(() => {
      this.scrollWaiting = false;
      clearTimeout(timeout);
    }, 1000);
  }
  handleSwipe(event) {
    if (this.props.currentStage > -1) {
      if (event.detail && (event.detail.direction === 'right' || event.detail.direction === 'up') && !this.scrollWaiting && this.props.currentStage + 1 < this.groupLength) {
        this.setScrollWait();
        this.props.goToStage(this.props.currentStage + 1);
      }
      if (event.detail && (event.detail.direction === 'left' || event.detail.direction === 'down') && !this.scrollWaiting && this.props.currentStage > 0) {
        this.setScrollWait();
        this.props.goToStage(this.props.currentStage - 1);
      }
    }
  }
  handleWheel(event) {
    const stage = this.props.data.stages[this.props.currentStage];
    if (!stage || (stage.overlay && !this.props.overlay)) {
      return;
    }
    if(!this.scrollWaiting) {
      if (event.deltaY > 0 && this.props.currentStage + 1 < this.groupLength) {
        this.setScrollWait();
        //console.log('going forward to stage', this.props.currentStage + 1, this.groupLength);
        this.props.goToStage(this.props.currentStage + 1);
        return;
      }
      if (event.deltaY < 0 && this.props.currentStage > 0) {
        this.setScrollWait();
        //console.log('going backward to stage', this.props.currentStage - 1, this.groupLength);
        this.props.goToStage(this.props.currentStage - 1);
        return;
      }
      if (event.deltaY > 0 && this.props.currentStage + 1 === this.groupLength) {
        this.setScrollWait();
        this.handleProgression(stage && stage.next);
        return;
      }
    }
  }

  handleProgression(next) {
    if(next) {
      if(next.hasOwnProperty('section')) {
        this.props.goToSection(next.section);
        if(next.hasOwnProperty('group')) {
          this.props.goToGroup(next.group);
        } else {
          this.props.goToGroup(0);
          this.props.goToStage(0);
        }
      } else if(next.hasOwnProperty('group')) {
        this.props.goToGroup(next.group);
        this.props.goToStage(0);
      }
    }
    else if(this.props.currentSection + 1 < this.props.numSections) {
      this.props.goToSection(this.props.currentSection + 1);
      
      //kludge for portfolio (this indexed-based navigation is horrid)
      if(this.props.currentSection + 1 === 1) {
        this.props.goToGroup(null);
        this.props.goToStage(null);
      } else {
        this.props.goToGroup(0);
        this.props.goToStage(0);
      }
    }
  }

  render() {
    const stage = this.props.data.stages[this.props.currentStage];
    const canProgress = this.props.currentStage === this.groupLength - 1 && 
                        this.props.currentSection + 1 < this.props.numSections;

    return (
      <section
        className={`group ${this.props.unityDisplay ? (this.props.currentSection === 0 ? 'noBackground' : 'hide') : ''} ${this.state.hidden ? 'noDisplay' : ''} receives-swipes`}
        id={this.props.data.id}
        tabIndex={'0'}
        onWheel={this.handleWheel}
      >
        { 
          !this.props.onboarding &&
          (canProgress || this.props.data.stages.length > 1) &&
          <TransitionGroup>
            {
              stage && (!stage.overlay || (stage.overlay && this.props.overlay)) &&
              <Transition timeout={0}>
                {
                  transitionState => (
                    <section className={`group__navigation ${transitionState}`}>
                      <StageTracker
                        stages={this.props.data.stages}
                        currentStage={this.props.currentStage}
                        canProgress={canProgress}
                        handleProgression={this.handleProgression}
                      />
                      {
                        this.props.currentStage < this.groupLength - 1 &&
                        <section className={`group__scrollDown ${this.state.scrollAnimating ? 'animating' : ''}`}>
                          <img
                            className="group__scrollDownArrow"
                            src={icons.scroll.svg}
                            alt="arrow"
                          />
                          <h4 className="group__scrollDownText">
                            Scroll down
                          </h4>
                        </section>

                      }
                    </section>
                  )
                }
              </Transition>
            }
          </TransitionGroup>
        }
        {
          stage && stage.moreInfo && 
          <MoreInfo data={stage.moreInfo} />
        }
        {
          this.props.data && this.props.data.stages.map((item, i) => {
            return (this.props.currentStage === i) &&
              <Stage key={`stage-${item.id}`} data={item} currentSection={this.props.currentSection} currentGroup={this.props.currentGroup} currentStage={this.props.currentStage} />;
          })
        }
      </section >
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    goToStage: (stageID) => {
      dispatch(actions.goToStage(stageID));
    },
    goToGroup: (groupID) => {
      dispatch(actions.goToGroup(groupID));
    },
    goToSection: (sectionID) => {
      dispatch(actions.goToSection(sectionID));
    },
  };
}

function mapStateToProps(state) {
  return {
    overlay: state.navigation.overlay,
    unityDisplay: state.navigation.unityDisplay,
    onboarding: state.navigation.onboarding,
  };
}

Group.propTypes = {
  data: propTypes.shape({
    title: propTypes.string,
    subtitle: propTypes.string,
    type: propTypes.string,
    //id: propTypes.string.isRequired,
    video: propTypes.string,
    imageSequence: propTypes.object,
    images: propTypes.array,
    stages: propTypes.array,
  }),
  goToStage: propTypes.func.isRequired,
  goToGroup: propTypes.func.isRequired,
  goToSection: propTypes.func.isRequired,
  currentSection: propTypes.number.isRequired,
  currentGroup: propTypes.number.isRequired,
  currentStage: propTypes.number.isRequired,
  numGroups: propTypes.number.isRequired,
  numSections: propTypes.number.isRequired,
  overlay: propTypes.bool.isRequired,
  unityDisplay: propTypes.bool.isRequired,
  onboarding: propTypes.bool.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(Group);

