import React from 'react';
import propTypes from 'prop-types';

import icons from '../../data/icons';

import './CalloutArea.scss';

const CalloutArea = props => (
  <section className={`calloutArea ${props.style}`}>
    <h2 className="calloutArea__title">
      {props.data.title}
    </h2>
    <section className={`calloutArea__main ${props.style}`}>
      {
        props.data.items.map((callout, i) => (
          <section key={"calloutArea__callout-"+i} className="calloutArea__callout" style={{ backgroundImage: `url(${icons[callout.icon.src][callout.icon.type]})` }}>
            {/*<img className="calloutArea__icon" src={icons[callout.icon.src][callout.icon.type]} alt={callout.icon.alt} />*/}
            <section className="calloutArea__textArea">
              {
                callout.boldText &&
                <h3 className="calloutArea__boldText">{callout.boldText}</h3>
              }
              {
                callout.text &&
                <p className="calloutArea__text">{callout.text}</p>
              }
              {
                callout.list &&
                <ul className="calloutArea__list">
                  {
                    callout.list.map((item, listPos) => (
                      <li key={"calloutArea__listItem-"+listPos} className="calloutArea__listItem">{item.text}</li>
                    ))
                  }
                </ul>
              }
            </section>
          </section>
        ))
      }
    </section>
  </section>
);

export default CalloutArea;

CalloutArea.propTypes = {
  data: propTypes.shape({
    title: propTypes.string,
    items: propTypes.array.isRequired,
  }),
  style: propTypes.string,
};
