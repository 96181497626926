import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { Route } from 'react-router';
import { ConnectedRouter } from 'react-router-redux';

import registerServiceWorker from './registerServiceWorker';
/* eslint-disable-next-line */
import swipe from './swipe';

import './index.css';
import App from './components/App/App';

import { store, history } from './redux'

//Setup the swipe events
swipe(window, document);

//Render the app
ReactDOM.render(
	<Provider store={store} >
		<ConnectedRouter history={history}>
			<div>
				<Route path="/" component={App} />
			</div>
		</ConnectedRouter>
	</Provider>,
	document.getElementById('root'),
);

registerServiceWorker();
