import React from "react";
import propTypes from "prop-types";
import { connect } from "react-redux";
import Button from "../Button/Button";

import * as actions from "../../actions/index";
import icons from "../../data/icons";
import "./Home.scss";

class Home extends React.Component {
  componentWillUnmount() {
    this.props.turnOffOnboarding();
  }

  render() {
    return (
      <section className="home">
        {this.props.onboarding && (
          <section className="home__onboarding">
            <section className="home__onBoardingNav">
              <section id="onBoardingNav__1">
                <figure className="home__onBoardingNav__line" />
                <div className="home__onboardingNumberIcon">1</div>
              </section>

              <section id="onBoardingNav__2">
                <figure className="home__onBoardingNav__line" />
                <div className="home__onboardingNumberIcon">2</div>
              </section>

              <section id="onBoardingNav__3">
                <figure className="home__onBoardingNav__line" />
                <div className="home__onboardingNumberIcon">3</div>
              </section>
            </section>
            <h1 className="home__header onboarding">How to use this app</h1>
            <section className="home__number">
              <div className="home__onboardingNumberIcon">1</div>
              <p className="home__onboardingText">
                Explore our 6 interactive disconnectors; for more information,
                click on the hotspots and Operate buttons
              </p>
            </section>
            <section className="home__number">
              <div className="home__onboardingNumberIcon">2</div>
              <p className="home__onboardingText">
                Discover Hitachi's high voltage Disconnectors global network on the
                map
              </p>
            </section>
            <section className="home__number">
              <div className="home__onboardingNumberIcon">3</div>
              <p className="home__onboardingText">
                See how many units are protecting networks in many different
                climates and applications
              </p>
            </section>
            <Button
              text="close"
              type="onboarding"
              onClick={() => this.props.turnOffOnboarding()}
            />
          </section>
        )}
        <div
          className={`home__overlay ${this.props.onboarding ? "darker" : ""}`}
        />
      </section>
    );
  }
}

function mapStateToProps(state) {
  return {
    onboarding: state.navigation.onboarding
  };
}

function mapDispatchToProps(dispatch) {
  return {
    turnOffOnboarding: () => {
      dispatch(actions.turnOffOnboarding());
    }
  };
}

Home.propTypes = {
  onboarding: propTypes.bool.isRequired,
  turnOffOnboarding: propTypes.func.isRequired
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Home);
