import React from 'react';
import propTypes from 'prop-types';
import { connect } from 'react-redux';
import * as actions from '../../actions/index';

import './MediaModal.scss';

const MediaModal = props => (
  <section className="mediaModal">
    <section className="mediaModal__inner">
      <section className="mediaModal__header">
        <h2 className="mediaModal__title">{props.media.title || ''}</h2>
        <button className="mediaModal__back" onClick={() => props.setMediaModal(null)}>
          Close
        </button>
      </section>
      <section className="mediaModal__content">
        <iframe title={props.media.title} src={props.media.content} width="100%" height="100%" />
        /*<iframe title={props.media.title} src={(window.require || navigator.onLine ||  !props.media.pdf) ? props.media.content : props.media.pdf} width="100%" height="100%"  sandbox={props.media.type === 'web' ? "allow-forms allow-pointer-lock allow-same-origin allow-scripts" : undefined} />*/
      </section>
    </section>
  </section>
);

function mapDispatchToProps(dispatch) {
  return {
    setMediaModal: (media) => {
      dispatch(actions.setMediaModal(media));
    },
  };
}

function mapStateToProps(state) {
  return {
    media: state.navigation.media,
  };
}

MediaModal.propTypes = {
  setMediaModal: propTypes.func.isRequired,
  media: propTypes.shape({
    //The title to display in the header
    title: propTypes.string.isRequired,
    //The url to set the iframe to (remote or local, file:// or http(s)://)
    content: propTypes.string.isRequired,
  }),
};

export default connect(mapStateToProps, mapDispatchToProps)(MediaModal);
