import React from 'react';
import propTypes from 'prop-types';

import icons from '../../data/icons';

import './IconList.scss';

const IconList = props => (
  <section className={`iconList ${props.style}`}>
    {props.data.items.map((icon, i) => {
      const iconSrc = (icons[icon.icon] && icons[icon.icon]['svg']) || icon.icon;
      return (
        <section key={'iconList__column-'+i} className="iconList__column">
          <section className="iconList__icon-container">
            <img className="iconList__icon" src={iconSrc} alt="" />
          </section>
          <section className="iconList__header">{icon.header}</section>
          <section className="iconList__text">{icon.text}</section>
          <hr />
          <section className="iconList__valueText">{icon.valueText}</section>
          <section className="iconList__value">{icon.value}</section>
        </section>
      );
    })}
  </section>
);

export default IconList;

IconList.propTypes = {
  data: propTypes.shape({
    items: propTypes.array.isRequired,
  }),
  style: propTypes.string,
};
